import { useEffect, useState, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { apiURL } from '../../utilities/URLs'

import backButton from '../../assets/icons/backButton.png'
import Recorder from 'recorder-js'
import { checkAudioRecordingTimeout, checkDecibelLevel, exportBufferGoogle, recordingTimeout, setCheckAudioRecordingTimeout, setRecordingTimeout } from '../../utilities/audio'
import { LanguageToChinese, Languages } from '../../utilities/Languages'

export default function LessonPlanChat() {
  const navigate = useNavigate()

  const location = useLocation()
  const lessonPlan = location.state || {}

  const [fetchingSessionToken, setFetchingSessionToken] = useState(true)
  const [sendingMessage, setSendingMessage] = useState(false)
  const [messages, setMessages] = useState([{text: lessonPlan.characterPrompt, sender: 'other', timestamp: new Date().toISOString(),}])
  const [sessionToken, setSessionToken] = useState('')
  const [inputText, setInputText] = useState('')
  const [error, setError] = useState('')

  const [microphoneStream, setMicrophoneStream] = useState(null)
  const [recorder, setRecorder] = useState(null)
  const [analyser, setAnalyser] = useState(null)

  const [langCode, setLangCode] = useState('')
  const [isRecording, setRecording] = useState(false)
  let [languageIn, setLanguageIn] = useState('')

  const audioRef = useRef(null)

  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    const initMicrophone = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
        const audioCtx = new (window.AudioContext)()
        const source = audioCtx.createMediaStreamSource(stream)

        const analyser = audioCtx.createAnalyser()
        analyser.fftSize = 2048

        source.connect(analyser)

        const recorder = new Recorder(audioCtx)

        setMicrophoneStream(stream)
        setRecorder(recorder)
        setAnalyser(analyser)
      } catch (error) {
        console.error('Error accessing microphone: ', error)
      }
    }

    initMicrophone()

    return () => {
      if (microphoneStream) microphoneStream.getTracks().forEach((track) => track.stop())
    }
  }, [])

  useEffect(() => {
    if (lessonPlan) {
      const handleGetSessionToken = async () => {
        const sessionTokenResponse = await fetch(`${apiURL}/startSessionWithLessonPlan?lessonPlanId=${lessonPlan.lessonPlanId}`)
        const sessionTokenData = await sessionTokenResponse.json()
        if (sessionTokenData.response.error) {
          setFetchingSessionToken(false)
          setError(sessionTokenData.response.error)
          setTimeout(() => {
            setError('')
          }, 4000)
        } else {
          setSessionToken(sessionTokenData.response.sessionToken)
          setFetchingSessionToken(false)
        }
      }

      handleGetSessionToken()
    }
  }, [lessonPlan])

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  const handleSend = async () => {
    if (inputText.trim() === '') return

    setSendingMessage(true)

    const newMessage = {
      text: inputText,
      sender: 'user',
      timestamp: new Date().toISOString(),
    }

    setMessages([...messages, newMessage])
    setInputText('')

    try {
      const response = await fetch(`${apiURL}/sendLessonMessage?sessionToken=${sessionToken}&message=${inputText}&voiceId=${lessonPlan.characterVoiceId}`)

      const responseData = await response.json()

      if (responseData.response.error) {
        setError(responseData.response.error)
        setTimeout(() => {
          setError('')
        }, 4000)
      } else {
        const receivedMessage = {
          text: responseData.response.message,
          sender: 'other',
          timestamp: new Date().toISOString(),
        }

        setMessages(prevMessages => [...prevMessages, receivedMessage])
        audioRef.current.src = 'data:audio/wav;base64,'+responseData.response.audioChunk
        audioRef.current.play()
      }
    } catch (error) {
      setError('Failed to send message')
      setTimeout(() => {
        setError('')
      }, 4000)
    } finally {
      setSendingMessage(false)
    }
  }

  const autoStopRecording = async () => {
    if(recordingTimeout !== null) {
      clearTimeout(recordingTimeout)
      setRecordingTimeout(null)
    }

    if(checkAudioRecordingTimeout !== null) {
      clearTimeout(checkAudioRecordingTimeout)
      setCheckAudioRecordingTimeout(null)
    }

    await recorder.stop()
    setRecording(false)
  }

  const startRecording = () => {
    setRecording(true)

    const timeoutId = setTimeout(() => {
      stopRecording()
    }, 10000)

    setRecordingTimeout(timeoutId)

    const checkAudioTimeoutId = setTimeout(() => {
      checkDecibelLevel(analyser, () => {
        autoStopRecording()
      })
    }, 2000)

    setCheckAudioRecordingTimeout(checkAudioTimeoutId)

    recorder.init(microphoneStream)
    recorder.start()
  }

  const stopRecording = async () => {
    if(recordingTimeout !== null) {
      clearTimeout(recordingTimeout)
      setRecordingTimeout(null)
    }

    if(checkAudioRecordingTimeout !== null) {
      clearTimeout(checkAudioRecordingTimeout)
      setCheckAudioRecordingTimeout(null)
    }

    setRecording(false)
    const buffer = await recorder.stop()

    const audio = exportBufferGoogle(buffer.buffer[0])

    sendAudioBlob(audio)
  }

  const sendAudioBlob = async (audio) => {
    if(langCode.length > 0) {
      setSendingMessage(true)

      const audioParams = {
        langCode: langCode
      }
      const formData = new FormData()
      formData.append('audioParams', JSON.stringify(audioParams))
      formData.append('audio', audio, 'audio.wav')
  
      fetch(`${apiURL}/sendAudioLessonMessage?sessionToken=${sessionToken}&voiceId=${lessonPlan.characterVoiceId}`, {
        method: 'POST',
        body: formData
      })
      .then((res) => res.json())
      .then((data) => {
        setSendingMessage(false)
        console.log(data)
        if(data.response.error) {
          setError(data.response.error)
          setTimeout(() => {
            setError('')
          }, 4000)
        } else {
          const userMessage = {
            text: data.response.userMessage,
            sender: 'user',
            timestamp: new Date().toISOString()
          }
          const receivedMessage = {
            text: data.response.message,
            sender: 'other',
            timestamp: new Date().toISOString(),
          }
    
          setMessages(prevMessages => [...prevMessages, userMessage, receivedMessage])

          audioRef.current.src = 'data:audio/wav;base64,'+data.response.audioChunk
          audioRef.current.play()
        }
      })
    } else {
      setError('Please Select An Input Language.')
      setTimeout(() => {
        setError('')
      }, 4000)
    }
  }

  if (!lessonPlan) {
    return <div style={styles.pageContainer}><p style={styles.errorMessage}>Lesson plan data is not available.</p></div>
  }

  return (
    <div style={styles.pageContainer}>
      <img onClick={() => navigate('/LessonPlansHome')} className='backButton' src={backButton} alt='backButton' />
      <div style={styles.sideContainer}>
        <img src={lessonPlan.characterImageURL} alt='Character' style={styles.characterImage} />
        <h2 style={styles.characterName}>{lessonPlan.characterName}</h2>
        <p style={styles.characterDesc}>{lessonPlan.characterDesc}</p>
      </div>
      <div style={styles.chatContainer}>
        <div style={styles.messagesContainer}>
          {fetchingSessionToken ? (
            <p style={styles.fetchingToken}>Fetching Session Token...</p>
          ) : (
            messages.map((msg, index) => (
              <div
                key={index}
                style={msg.sender === 'user' ? styles.userMessage : styles.aiMessage}
              >
                {msg.text}
              </div>
            ))
          )}
          <div ref={messagesEndRef} />
        </div>
        {error && <div style={styles.sendingMessage}>{error}</div>}
        {sendingMessage && <div style={styles.sendingMessage}>Sending message...</div>}
        <div style={styles.inputContainer}>
          <input
            type='text'
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            style={styles.input}
            disabled={sendingMessage}
          />
          <button onClick={handleSend} style={(fetchingSessionToken || sendingMessage) ? styles.disabledSendButton : styles.sendButton} disabled={fetchingSessionToken || sendingMessage}>Send</button>
          <button
            style={(fetchingSessionToken || sendingMessage) ? styles.disabledSendButton : styles.sendButton}
            onClick={() => {
              isRecording ? stopRecording() : startRecording()
            }}
            >
            {isRecording ? 'Stop Mic' : 'Start Mic'}
          </button>
          <select id='languageInSelect' className='languageSelect' value={languageIn} onChange={(e) => { setLangCode(Languages[e.target.value]); setLanguageIn(e.target.value) } }>
            <option value="" disabled selected>Select input language</option>
            {Object.keys(Languages).map((language) => {
              return(
                <option id='optionBar' key={language} value={language}>{language + ' / ' + LanguageToChinese[language]}</option>
              )
            })}
          </select>
        </div>
      </div>
      <audio ref={audioRef} />
    </div>
  )
}

const styles = {
  fetchingToken: {
    color: '#fff',
    alignSelf: 'center',
    fontFamily: 'Roboto, sans-serif',
  },
  pageContainer: {
    display: 'flex',
    height: '100vh',
    width: '100vw',
    backgroundColor: '#2c2c2c',
  },
  sideContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40%',
    padding: '20px'
  },
  characterImage: {
    width: '40%',
    objectFit: 'cover',
    aspectRatio: '1 / 1',
    borderRadius: '10px',
    marginBottom: '10px',
  },
  characterName: {
    color: '#fff',
    margin: 0,
    marginBottom: '25px',
    textAlign: 'center',
    fontFamily: 'Roboto, sans-serif',
  },
  characterDesc: {
    color: '#fff',
    margin: 0,
    textAlign: 'center',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 'lighter'
  },
  chatContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    scale: '0.9',
    backgroundColor: '#1e1e1e',
    border: '1px solid #ccc',
    fontFamily: 'Roboto, sans-serif',
  },
  messagesContainer: {
    flex: 1,
    padding: '10px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    backgroundColor: '#2c2c2c',
  },
  userMessage: {
    alignSelf: 'flex-end',
    backgroundColor: '#DCF8C6',
    padding: '10px',
    borderRadius: '10px',
    maxWidth: '60%',
    wordWrap: 'break-word',
  },
  aiMessage: {
    alignSelf: 'flex-start',
    backgroundColor: '#F1F0F0',
    padding: '10px',
    borderRadius: '10px',
    maxWidth: '60%',
    wordWrap: 'break-word',
  },
  sendingMessage: {
    alignSelf: 'center',
    marginBottom: '10px',
    color: '#888',
    fontStyle: 'italic',
  },
  inputContainer: {
    display: 'flex',
    padding: '10px',
    borderTop: '1px solid #ccc',
    backgroundColor: '#1e1e1e',
  },
  input: {
    flex: 1,
    padding: '10px',
    borderRadius: '10px',
    border: '1px solid #ccc',
    marginRight: '10px',
    backgroundColor: '#333',
    color: '#fff',
  },
  sendButton: {
    padding: '10px 20px',
    marginRight: '10px',
    borderRadius: '10px',
    border: 'none',
    backgroundColor: '#007BFF',
    color: 'white',
    cursor: 'pointer',
  },
  disabledSendButton: {
    padding: '10px 20px',
    marginRight: '10px',
    borderRadius: '10px',
    border: 'none',
    backgroundColor: '#ccc',
    color: 'white',
    cursor: 'pointer',
  },
  errorMessage: {
    color: 'white',
  },
}
