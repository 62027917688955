import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { formatNumber, truncateString } from "./Extensions.js"
import { toast } from "react-toastify"
import { ConfirmDeleteCustomizedCharacter, ConfirmDeleteUserPersona, ConfirmReport, ErrorUnlockCharacter, NoMoreInteractions, RegisterForAnAccount, SignInToFavorite, SignInToLike, SignInToReport } from "./PopUpWindows.js"
import { LoginCreds } from "./loginCreds.js"
import { Characters } from "./Characters.js"
import { apiURL } from "./URLs.js"
import CharacterModal from "../pages/CharacterModal.js"

import infoIcon from '../assets/icons/infoIcon.png'
import likedIcon from '../assets/icons/likedIcon.png'
import notLikedIcon from '../assets/icons/notLikedIcon.png'
import blankProfileImage from '../assets/icons/BlankProfilePicture.png'
import shareIcon from '../assets/icons/shareIcon.png'
import settingsIcon from '../assets/icons/settingsIcon.png'
import deleteIcon from '../assets/icons/deleteIcon.png'
import notFavoritedIcon from '../assets/icons/notFavoritedIcon.png'
import favoritedIcon from '../assets/icons/favoritedIcon.png'
import lockedIcon from '../assets/icons/lockedIcon.png'
import { Avatar } from "@readyplayerme/visage"

const handleClickInfo = (e, setCharacterModalIsOpen) => {
  e.stopPropagation()
  setCharacterModalIsOpen(true)
}

const handleCopyLink = async (e, characterId) => {
  e.stopPropagation()
  await navigator.clipboard.writeText(`${window.location.origin}/#/CharacterPage/${characterId}`)
  toast.success('Link Copied!', {
    position: toast.POSITION.BOTTOM_CENTER,
    autoClose: 2000
  })
}

const handleCharacterPage = async (navigate, character, characterVariant, setNoInteractionsLeft, setRegisterForAnAccount, setErrorUnlockCharacter, setUnlockPoints) => {
  if(character.isUnlocked === 1) {
    if(LoginCreds.devToken !== '') {
      if(LoginCreds.userInteractions > 0)
        navigate('/CharacterPage', { state: { character: character, characterVariant: characterVariant, isDatingGame: LoginCreds.userMatched.includes(character.characterId) ? false : true } })
      else
        setNoInteractionsLeft(true)
    } else {
      var interactions = localStorage.getItem('freeInteractions')
      if(interactions === null)
        interactions = 0
      if(interactions < 25)
        navigate('/CharacterPage', { state: { character: character, characterVariant: characterVariant, isDatingGame: LoginCreds.userMatched.includes(character.characterId) ? false : true } })
      else
        setRegisterForAnAccount(true)
    }
  } else {
    if(LoginCreds.userDatingUnlockPoints > 0) {
      const unlockCharacterResponse = await fetch(`${apiURL}/unlockCharacter?devToken=${LoginCreds.devToken}&characterId=${character.characterId}`, {
        method: 'POST'
      })
      await unlockCharacterResponse.json()
      setUnlockPoints(LoginCreds.userDatingUnlockPoints-1)
      character.isUnlocked = 1
      LoginCreds.userDatingUnlockPoints--
    } else {
      setErrorUnlockCharacter(true)
    }
  }
}

export function CharacterTile({character, characterVariant, setSortedCharacters, setCategories, selectedButton, setLikedCharacters, setFavoriteCharacters, setUnlockPoints}) {
  const navigate = useNavigate()
  const [characterModalIsOpen, setCharacterModalIsOpen] = useState(false)
  const [noInteractionsLeft, setNoInteractionsLeft] = useState(false)
  const [registerForAnAccount, setRegisterForAnAccount] = useState(false)
  const [errorUnlockCharacter, setErrorUnlockCharacter] = useState(false)
  const [signInToLike, setSignInToLike] = useState(false)
  const [signInToFavorite, setSignInToFavorite] = useState(false)
  const [showReport, setShowReport] = useState(false)
  const [showReportError, setShowReportError] = useState(false)

  const handleReport = (e) => {
    e.stopPropagation()
    if(LoginCreds.devToken !== '')
      setShowReport(true)
    else
      setShowReportError(true)
  }

  const handleCloseModal = (e) => {
    if(e !== undefined)
      e.stopPropagation()
    setCharacterModalIsOpen(false)
  }

  const handleLogin = (e) => {
    e.stopPropagation()
    setSignInToLike(true)
  }

  const handleShowSignInFavorite = (e) => {
    e.stopPropagation()
    setSignInToFavorite(true)
  }

  const handleSendLike = (e, character, characterVariant) => {
    e.stopPropagation()

    const likeInfo = {
      devToken: LoginCreds.devToken,
      characterId: character.characterId
    }
    const formData = new FormData()
    formData.append('likeInfo', JSON.stringify(likeInfo))

    fetch(`${apiURL}/sendLike`, {
      method: 'POST',
      body: formData
    })
    .then((res) => res.json())
    .then((data) => {
      if(characterVariant === 0) {
        if(setCategories && setSortedCharacters && selectedButton) {
          const characterIndex = Characters.categoryCharacters[selectedButton].characters.findIndex((char) => char.characterId === character.characterId)
          Characters.categoryCharacters[selectedButton].characters[characterIndex].characterLikeCount = data.response.updatedLikeCount
          setCategories([...Characters.categoryCharacters])
        }
      } else {
        const characterIndex = Characters.sortedCharacters.findIndex((char) => char.characterId === character.characterId)
        Characters.sortedCharacters[characterIndex].characterLikeCount = data.response.updatedLikeCount
        if(setSortedCharacters)
          setSortedCharacters([...Characters.sortedCharacters])
      }
      LoginCreds.userLikes.push(character.characterId)

      if(setLikedCharacters)
        setLikedCharacters(Characters.allCharacters.filter(character => LoginCreds.userLikes.includes(character.characterId)))
    })
  }
  
  const handleRemoveLike = (e, character, characterVariant) => {
    e.stopPropagation()
    fetch(`${apiURL}/deleteLike?devToken=${LoginCreds.devToken}&characterId=${character.characterId}`, {
      method: 'DELETE'
    })
    .then((res) => res.json())
    .then((data) => {
      if(characterVariant === 0) {
        if(setCategories && setSortedCharacters && selectedButton) {
          const characterIndex = Characters.categoryCharacters[selectedButton].characters.findIndex((char) => char.characterId === character.characterId)
          Characters.categoryCharacters[selectedButton].characters[characterIndex].characterLikeCount = data.response.updatedLikeCount
          setCategories([...Characters.categoryCharacters])
        }
      } else {
        const characterIndex = Characters.sortedCharacters.findIndex((char) => char.characterId === character.characterId)
        Characters.sortedCharacters[characterIndex].characterLikeCount = data.response.updatedLikeCount
        if(setSortedCharacters)
          setSortedCharacters([...Characters.sortedCharacters])
      }
      const index = LoginCreds.userLikes.findIndex((item) => item === character.characterId)
      LoginCreds.userLikes.splice(index, 1)

      if(setLikedCharacters)
        setLikedCharacters(Characters.allCharacters.filter(character => LoginCreds.userLikes.includes(character.characterId)))
    })
  }

  const handleSendFavorite = (e, character) => {
    e.stopPropagation()

    const favoriteInfo = {
      devToken: LoginCreds.devToken,
      characterId: character.characterId
    }
    const formData = new FormData()
    formData.append('favoriteInfo', JSON.stringify(favoriteInfo))

    fetch(`${apiURL}/sendDatingFavorite`, {
      method: 'POST',
      body: formData
    })
    .then((res) => res.json())
    .then(() => { 
      LoginCreds.userFavorites.push(character.characterId)

      if(setCategories && setSortedCharacters) {
        //setCategories([...Characters.categoryCharacters])
        setSortedCharacters([...Characters.sortedCharacters])
      }

      if(setFavoriteCharacters)
        setFavoriteCharacters(Characters.allCharacters.filter(character => LoginCreds.userFavorites.includes(character.characterId)))
    })
  }
  
  const handleRemoveFavorite = (e, character) => {
    e.stopPropagation()
    fetch(`${apiURL}/deleteDatingFavorite?devToken=${LoginCreds.devToken}&characterId=${character.characterId}`, {
      method: 'DELETE'
    })
    .then((res) => res.json())
    .then((data) => {
      const index = LoginCreds.userFavorites.findIndex((item) => item === character.characterId)
      LoginCreds.userFavorites.splice(index, 1)

      if(setCategories && setSortedCharacters) {
        //setCategories([...Characters.categoryCharacters])
        setSortedCharacters([...Characters.sortedCharacters])
      }

      if(setFavoriteCharacters)
        setFavoriteCharacters(Characters.allCharacters.filter(character => LoginCreds.userFavorites.includes(character.characterId)))
    })
  }

  return (
    <>
      <div key={character.characterId} onClick={() => handleCharacterPage(navigate, character, character.characterIsPublic ? 1 : 0, setNoInteractionsLeft, setRegisterForAnAccount, setErrorUnlockCharacter, setUnlockPoints)} className={character.isUnlocked === 0 ? 'lockedCharacter' : 'character'}>
        {character.isUnlocked === 0 && (
          <img className='lockedIcon' src={lockedIcon} />
        )}
        <div className='infoIconDiv'>
          <img className='infoIcon' onClick={(e) => {handleClickInfo(e, setCharacterModalIsOpen)}} src={infoIcon} alt='infoIcon'/>
        </div>
        <div className='favoriteIconDiv'>
          {LoginCreds.userFavorites.includes(character.characterId) ? (
            <img style={{color: 'black'}} onClick={(e) => {character.isUnlocked === 1 && handleRemoveFavorite(e, character)}} src={favoritedIcon} alt='likedIcon'/>
          ) : (
            <img onClick={(e) => { LoginCreds.devToken !== ''? (character.isUnlocked === 1 && handleSendFavorite(e, character)) : handleShowSignInFavorite(e)}} src={notFavoritedIcon} alt='notLikedIcon'/>
          )}
        </div>
        {character.characterImage !== "" ? (
          <img className='characterImage' src={character.characterImage} alt='avatarImg' />
        ) : (
          <img className='characterBlankImage' src={blankProfileImage} alt='avatarImg' />
        )}
        <p className='characterName'>{character.characterName}</p>
        <p className='characterDesc'>{truncateString(character.characterDesc, 130)}</p>
        <div className='characterBottomBar'>
          {character.characterIsPublic ? (
            <p>{truncateString(character.characterCreatedBy, 12)}</p>
          ) : (
            <p></p>
          )}
          
        </div>
      </div>
      <CharacterModal
        currentCharacter={character}
        characterModalIsOpen={characterModalIsOpen}
        handleCloseModal={handleCloseModal}
        handleReport={handleReport}
      />
      <NoMoreInteractions
        showPopUp={noInteractionsLeft}
        setShowPopUp={setNoInteractionsLeft}
      />
      <RegisterForAnAccount
        showPopUp={registerForAnAccount}
        setShowPopUp={setRegisterForAnAccount}
      />
      <SignInToLike
        showPopUp={signInToLike}
        setShowPopUp={setSignInToLike}
      />
      <SignInToFavorite
        showPopUp={signInToFavorite}
        setShowPopUp={setSignInToFavorite}
        />
      <ConfirmReport
        showPopUp={showReport}
        setShowPopUp={setShowReport}
        character={character}
        setSortedCharacters={setSortedCharacters}
        setCategories={setCategories}
      />
      <SignInToReport
        showPopUp={showReportError}
        setShowPopUp={setShowReportError}
      />
      <ErrorUnlockCharacter
        showPopUp={errorUnlockCharacter}
        setShowPopUp={setErrorUnlockCharacter}
      />
    </>
  )
}

export function UserPersonaTile({character, setUserPersonas, setLikedCharacters, setFavoriteCharacters}) {
  const navigate = useNavigate()
  const [characterModalIsOpen, setCharacterModalIsOpen] = useState(false)
  const [noInteractionsLeft, setNoInteractionsLeft] = useState(false)
  const [registerForAnAccount, setRegisterForAnAccount] = useState(false)
  const [signInToLike, setSignInToLike] = useState(false)
  const [signInToFavorite, setSignInToFavorite] = useState(false)
  const [showReport, setShowReport] = useState(false)
  const [showReportError, setShowReportError] = useState(false)

  const handleReport = (e) => {
    e.stopPropagation()
    if(LoginCreds.devToken !== '')
      setShowReport(true)
    else
      setShowReportError(true)
  }

  const handleCloseModal = (e) => {
    if(e !== undefined)
      e.stopPropagation()
    setCharacterModalIsOpen(false)
  }

  const handleLogin = (e) => {
    e.stopPropagation()
    setSignInToLike(true)
  }

  const handleShowSignInFavorite = (e) => {
    e.stopPropagation()
    setSignInToFavorite(true)
  }

  const handleSendLike = (e, character) => {
    e.stopPropagation()

    const likeInfo = {
      devToken: LoginCreds.devToken,
      characterId: character.userPersonaId
    }
    const formData = new FormData()
    formData.append('likeInfo', JSON.stringify(likeInfo))

    fetch(`${apiURL}/sendLike`, {
      method: 'POST',
      body: formData
    })
    .then((res) => res.json())
    .then((data) => {
      const userPersonaIndex = Characters.userPersonas.findIndex((persona) => persona.userPersonaId === character.userPersonaId)
      Characters.userPersonas[userPersonaIndex].userPersonaLikeCount = data.response.updatedLikeCount
      if(setUserPersonas)
        setUserPersonas([...Characters.userPersonas])
      LoginCreds.userLikes.push(character.userPersonaId)

      if(setLikedCharacters)
        setLikedCharacters(Characters.userPersonas.filter(userPersona => LoginCreds.userLikes.includes(userPersona.userPersonaId)))
    })
  }
  
  const handleRemoveLike = (e, character) => {
    e.stopPropagation()
    fetch(`${apiURL}/deleteLike?devToken=${LoginCreds.devToken}&characterId=${character.userPersonaId}`, {
      method: 'DELETE'
    })
    .then((res) => res.json())
    .then((data) => {
      const userPersonaIndex = Characters.userPersonas.findIndex((persona) => persona.userPersonaId === character.userPersonaId)
      Characters.userPersonas[userPersonaIndex].userPersonaLikeCount = data.response.updatedLikeCount
      if(setUserPersonas)
        setUserPersonas([...Characters.userPersonas])

      const index = LoginCreds.userLikes.findIndex((item) => item === character.userPersonaId)
      LoginCreds.userLikes.splice(index, 1)

      if(setLikedCharacters)
        setLikedCharacters(Characters.userPersonas.filter(userPersona => LoginCreds.userLikes.includes(userPersona.userPersonaId)))
    })
  }

  const handleSendFavorite = (e, character) => {
    e.stopPropagation()
    
    const favoriteInfo = {
      devToken: LoginCreds.devToken,
      characterId: character.characterId
    }
    const formData = new FormData()
    formData.append('favoriteInfo', JSON.stringify(favoriteInfo))

    fetch(`${apiURL}/sendFavorite`, {
      method: 'POST',
      body: formData
    })
    .then((res) => res.json())
    .then(() => { 
      LoginCreds.userFavorites.push(character.userPersonaId)
      if(setUserPersonas)
        setUserPersonas([...Characters.userPersonas])

      if(setFavoriteCharacters)
        setFavoriteCharacters(Characters.allCharacters.filter(character => LoginCreds.userFavorites.includes(character.characterId)))
    })
  }
  
  const handleRemoveFavorite = (e, character) => {
    e.stopPropagation()
    fetch(`${apiURL}/removeFavorite?devToken=${LoginCreds.devToken}&characterId=${character.userPersonaId}`)
    .then((res) => res.json())
    .then(() => {
      const index = LoginCreds.userFavorites.findIndex((item) => item === character.userPersonaId)
      LoginCreds.userFavorites.splice(index, 1)

      if(setUserPersonas)
        setUserPersonas([...Characters.userPersonas])

      if(setFavoriteCharacters)
        setFavoriteCharacters(Characters.allCharacters.filter(character => LoginCreds.userFavorites.includes(character.characterId)))
    })
  }

  return (
    <>
      <div key={character.userPersonaId} onClick={() => handleCharacterPage(navigate, character, 2, setNoInteractionsLeft, setRegisterForAnAccount)} className='character'>
        <div className='infoIconDiv'>
          <img className='infoIcon' onClick={(e) => {handleClickInfo(e, setCharacterModalIsOpen)}} src={infoIcon} alt='infoIcon'/>
        </div>
        <div className='favoriteIconDiv'>
          {LoginCreds.userFavorites.includes(character.userPersonaId) ? (
            <img style={{color: 'black'}} onClick={(e) => {handleRemoveFavorite(e, character)}} src={favoritedIcon} alt='likedIcon'/>
          ) : (
            <img onClick={(e) => { LoginCreds.devToken !== '' ? handleSendFavorite(e, character) : handleShowSignInFavorite(e)}} src={notFavoritedIcon} alt='notLikedIcon'/>
          )}
        </div>
        {character.userPersonaImage !== "" ? (
          <img src={character.userPersonaImage} alt='avatarImg' />
        ) : (
          <img className='characterBlankImage' src={blankProfileImage} alt='avatarImg' />
        )}
        <p className='characterName'>{character.userPersonaName}</p>
        <p className='characterDesc'>{truncateString(character.userPersonaDesc, 130)}</p>
        <div className='characterBottomBar'>
          <p>{truncateString(character.userPersonaCreatedBy, 12)}</p>
          <div className='characterBottomBarRight'>
            <div className='characterBottomBarLike'>
              <div className='characterBottomBarLikeCount'>
                <p><b>{formatNumber(character.userPersonaLikeCount)}</b></p>
              </div>
              {LoginCreds.userLikes.includes(character.userPersonaId) ? (
                <img onClick={(e) => {handleRemoveLike(e, character)}} src={likedIcon} alt='likedIcon'/>
              ) : (
                <img onClick={(e) => {LoginCreds.devToken !== '' ? handleSendLike(e, character) : handleLogin(e)}} src={notLikedIcon} alt='likedIcon'/>
              )}
            </div>
            <div className='favoriteIconMobileDiv'>
              {LoginCreds.userFavorites.includes(character.userPersonaId) ? (
                <img style={{color: 'black'}} onClick={(e) => {handleRemoveFavorite(e, character)}} src={favoritedIcon} alt='likedIcon'/>
              ) : (
                <img onClick={(e) => { LoginCreds.devToken !== '' ? handleSendFavorite(e, character) : handleShowSignInFavorite(e)}} src={notFavoritedIcon} alt='notLikedIcon'/>
              )}
            </div>
            <img onClick={(e) => {handleCopyLink(e, character.userPersonaId)}} src={shareIcon} alt='shareIcon'/>
          </div>
        </div>
      </div>
      <CharacterModal
        currentCharacter={character}
        characterModalIsOpen={characterModalIsOpen}
        handleCloseModal={handleCloseModal}
        handleReport={handleReport}
      ></CharacterModal>
      <NoMoreInteractions
        showPopUp={noInteractionsLeft}
        setShowPopUp={setNoInteractionsLeft}
      />
      <RegisterForAnAccount
        showPopUp={registerForAnAccount}
        setShowPopUp={setRegisterForAnAccount}
      />
      <SignInToLike
        showPopUp={signInToLike}
        setShowPopUp={setSignInToLike}
      />
      <SignInToFavorite
        showPopUp={signInToFavorite}
        setShowPopUp={setSignInToFavorite}
      />
      <ConfirmReport
        showPopUp={showReport}
        setShowPopUp={setShowReport}
        character={character}
        setUserPersonas={setUserPersonas}
      />
      <SignInToReport
        showPopUp={showReportError}
        setShowPopUp={setShowReportError}
      />
    </>
  )
}

const handleEditCharacter = (e, character, navigate) => {
  e.stopPropagation()
  navigate('/EditCharacter', { state: { character: character } })
}

const handleConfirmDeleteCharacter = (e, setShowDelete) => {
  e.stopPropagation()
  setShowDelete(true)
}

export function MyCharacter({character, setCustomizedCharacters, setSortedCharacters}) {
  const navigate = useNavigate()
  const [showDelete, setShowDelete] = useState(false)
  const [noInteractionsLeft, setNoInteractionsLeft] = useState(false)
  const [registerForAnAccount, setRegisterForAnAccount] = useState(false)

  return(
    <>
      <div key={character.characterId} onClick={() => handleCharacterPage(navigate, character, 1, setNoInteractionsLeft, setRegisterForAnAccount)} className='character'>
        <img src={character.characterImage} alt='avatarImg' />
        <p className='characterName'>{character.characterName}</p>
        <p className='characterDesc'>{truncateString(character.characterDesc, 80)}</p>
        <div className='characterMyCharacterBar'>
          <img onClick={(e) => handleEditCharacter(e, character, navigate)} src={settingsIcon} alt='settingsIcon'/>
          <img className='characterDeleteButton' onClick={(e) => handleConfirmDeleteCharacter(e, setShowDelete)} src={deleteIcon} alt='deleteIcon'/>
        </div>
      </div>
      <ConfirmDeleteCustomizedCharacter
        showDelete={showDelete}
        characterId={character.characterId}
        characterName={character.characterName}
        setShowDelete={setShowDelete}
        setCustomizedCharacters={setCustomizedCharacters}
        setSortedCharacters={setSortedCharacters}
      />
      <NoMoreInteractions
        showPopUp={noInteractionsLeft}
        setShowPopUp={setNoInteractionsLeft}
      />
      <RegisterForAnAccount
        showPopUp={registerForAnAccount}
        setShowPopUp={setRegisterForAnAccount}
      />
    </>
  )
}

export function MyUserPersona({userPersona, setUserPersona}) {
  const navigate = useNavigate()
  const [showDelete, setShowDelete] = useState(false)
  const [noInteractionsLeft, setNoInteractionsLeft] = useState(false)
  const [registerForAnAccount, setRegisterForAnAccount] = useState(false)

  return(
    <>
      <div className='userPersonaContainer'>
        <div className='userPersona'>
          {userPersona.userPersonaImage ? (
            <img src={userPersona.userPersonaImage} alt='userPersonaImage' />
          ) : (
            <div className='userPersonaAvatarImage'>
              <Avatar modelSrc={userPersona.userPersonaRPMAvatarURL} />
            </div>
          )}
          <h2>{userPersona.userPersonaName}</h2>
          <p>Pronouns: {userPersona.userPersonaPronouns}</p>
          <p>{userPersona.userPersonaDesc}</p>
          <div className='userPersonaButtons'>
            <button onClick={() => handleCharacterPage(navigate, userPersona, 2, setNoInteractionsLeft, setRegisterForAnAccount)}>Talk To Persona</button>
            <button onClick={() => navigate('/EditPersona', { state: { userPersona: userPersona }})}>Edit Persona</button>
            <button onClick={() => setShowDelete(true)}>Delete Persona</button>
          </div>
        </div>
      </div>
      <ConfirmDeleteUserPersona
        showDelete={showDelete}
        userPersona={userPersona}
        setShowDelete={setShowDelete}
        setUserPersona={setUserPersona}
      />
      <NoMoreInteractions
        showPopUp={noInteractionsLeft}
        setShowPopUp={setNoInteractionsLeft}
      />
      <RegisterForAnAccount
        showPopUp={registerForAnAccount}
        setShowPopUp={setRegisterForAnAccount}
      />
    </>
  )
}


/*
<div className='characterBottomBarRight'>
            <div className='characterBottomBarLike'>
              <div className='characterBottomBarLikeCount'>
                <p><b>{formatNumber(character.characterLikeCount)}</b></p>
              </div>
              {LoginCreds.userLikes.includes(character.characterId) ? (
                <img onClick={(e) => {handleRemoveLike(e, character, characterVariant)}} src={likedIcon} alt='likedIcon'/>
              ) : (
                <img onClick={(e) => { LoginCreds.devToken !== '' ? handleSendLike(e, character, characterVariant) : handleLogin(e)}} src={notLikedIcon} alt='notLikedIcon'/>
              )}
            </div>
            <div className='favoriteIconMobileDiv'>
              {LoginCreds.userFavorites.includes(character.characterId) ? (
                <img style={{color: 'black'}} onClick={(e) => {handleRemoveFavorite(e, character)}} src={favoritedIcon} alt='likedIcon'/>
              ) : (
                <img onClick={(e) => { LoginCreds.devToken !== '' ? handleSendFavorite(e, character) : handleShowSignInFavorite(e)}} src={notFavoritedIcon} alt='notLikedIcon'/>
              )}
            </div>
            <img onClick={(e) => {handleCopyLink(e, character.characterId)}} src={shareIcon} alt='shareIcon' className='shareIcon'/>
          </div>
*/