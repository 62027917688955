import { Characters } from "./Characters"
import { LoginCreds } from "./loginCreds"

export function formatNumber(number) {
  const suffixes = ["", "K", "M", "B", "T"]
  var suffixIndex = 0

  while(number > 1000 && suffixIndex < suffixes.length-1) {
    suffixIndex++
    number /= 1000
  }

  if(suffixIndex === 0)
    return `${number}`

  return `${number.toFixed(1)}${suffixes[suffixIndex]}`
}

export function truncateString(string, maxLength) {
  return string.length > maxLength ? string.slice(0, maxLength).trim()+'...' : string
}

export function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email)
}

export function isValidUserName(userName) {
  const userNameRegex = /^[a-zA-Z0-9_]+$/
  return userNameRegex.test(userName)
}

export function SetLoginCreds(account) {
  LoginCreds.devToken = account.devToken
  LoginCreds.isGoogleAccount = account.isGoogleAccount
  LoginCreds.email = account.email
  LoginCreds.password = account.password
  LoginCreds.profileImage = account.profileImage
  LoginCreds.userInteractions = account.userInteractions
  LoginCreds.userDatingInteractions = account.userDatingInteractions
  LoginCreds.matchSlots = account.matchSlots
  LoginCreds.userName = account.userName
  LoginCreds.userPersonaId = account.userPersonaId
  LoginCreds.subTier = account.subTier
  LoginCreds.userDatingUnlockPoints = account.userDatingUnlockPoints

  localStorage.removeItem('currentHomePage')
  localStorage.removeItem('currentCommunityPage')
}

export function ResetLoginCreds() {
  LoginCreds.devToken = ''
  LoginCreds.isGoogleAccount = 0
  LoginCreds.email = ''
  LoginCreds.password = ''
  LoginCreds.profileImage = ''
  LoginCreds.userInteractions = 0
  LoginCreds.userDatingInteractions = 0
  LoginCreds.matchSlots = 5
  LoginCreds.userName = ''
  LoginCreds.userPersonaId = null
  LoginCreds.userLikes = []
  LoginCreds.userFavorites = []
  LoginCreds.subTier = 0
  LoginCreds.userDatingUnlockPoints = 0

  if(Characters.customizedCharacters.length > 0)
    Characters.customizedCharacters = []

  localStorage.removeItem('currentHomePage')
  localStorage.removeItem('currentCommunityPage')
}

export function convertToPercentage(number, maxNumber) {
  return Math.round((number/maxNumber)*100)
}