export const Languages = {
  'English': 'en-US',
  'Chinese': 'cmn-Hans-CN',
  'Spanish': 'es-MX',
  'French': 'fr-FR',
  'German': 'de-DE',
  'Italian': 'it-IT',
  'Japanese': 'ja-JP',
  'Korean': 'ko-KR',
  'Russian': 'ru-RU',
  'Arabic': 'ar-IL'
}

export const LanguageToChinese = {
  'English': '英语',
  'Chinese': '中文',
  'Spanish': '西班牙语',
  'French': '法语',
  'German': '德语',
  'Italian': '意大利语',
  'Japanese': '日语',
  'Korean': '韩语',
  'Russian': '俄语',
  'Arabic': '阿拉伯'
}

export const demoCharacters = [
  // Poppy
  {
    characterId: '10b8f9b9-5c51-4e90-b186-9bc925743578',
    voiceId: 'ErXwobaYiN019PkySvjV',
    defaultLang: undefined
  },

  // Miles
  {
    characterId: '1a77d5b7-7260-4139-a110-d47a215a7610',
    voiceId: 'ErXwobaYiN019PkySvjV',
    defaultLang: undefined
  },

  // Bobby
  {
    characterId: '35e48d6c-cd9b-4b22-9cdc-11139109796a',
    voiceId: 'ErXwobaYiN019PkySvjV',
    defaultLang: 'Chinese'
  },

  // Brittany
  {
    characterId: '19976243-1019-403e-b992-6e1fdda1e1ba',
    voiceId: 'ThT5KcBeYPX3keUQqHPh',
    defaultLang: 'French'
  },

  // Penny
  {
    characterId: '55ac9d78-0c66-4fb5-91fa-a8e60dbd5834',
    voiceId: 'ThT5KcBeYPX3keUQqHPh',
    defaultLang: 'English'
  },

  // Joseph
  {
    characterId: '6e81ff69-4917-4029-bae9-5d76177c97ab',
    voiceId: 'ErXwobaYiN019PkySvjV',
    defaultLang: 'Japanese'
  },

  // Adam
  {
    characterId: '0864a875-f11e-4b6d-a8ef-85985fa495fd',
    voiceId: 'ErXwobaYiN019PkySvjV',
    defaultLang: 'Arabic'
  },

  // Asher
  {
    characterId: '74585bac-5bef-47f2-a02e-d3dc2ff55700',
    voiceId: 'ErXwobaYiN019PkySvjV',
    defaultLang: 'Spanish'
  }
]

export const elevenLabsVoices = [
  {
    voiceId: 'pNInz6obpgDQGcFmaJgB',
    voiceName: 'Adam'
  },
  {
    voiceId: 'Xb7hH8MSUJpSbSDYk0k2',
    voiceName: 'Alice'
  },
  {
    voiceId: 'ErXwobaYiN019PkySvjV',
    voiceName: 'Antoni'
  },
  {
    voiceId: 'VR6AewLTigWG4xSOukaG',
    voiceName: 'Arnold'
  },
  {
    voiceId: 'pqHfZKP75CvOlQylNhV4',
    voiceName: 'Bill'
  },
  {
    voiceId: 'nPczCjzI2devNBz1zQrb',
    voiceName: 'Brian'
  },
  {
    voiceId: 'N2lVS1w4EtoT3dr4eOWO',
    voiceName: 'Callum'
  },
  {
    voiceId: 'IKne3meq5aSn9XLyUdCD',
    voiceName: 'Charlie'
  },
  {
    voiceId: 'XB0fDUnXU5powFXDhCwa',
    voiceName: 'Charlotte'
  },
  {
    voiceId: 'iP95p4xoKVk53GoZ742B',
    voiceName: 'Chris'
  },
  {
    voiceId: '2EiwWnXFnvU5JabPnv8n',
    voiceName: 'Clyde'
  },
  {
    voiceId: 'onwK4e9ZLuTAKqWW03F9',
    voiceName: 'Daniel'
  },
  {
    voiceId: 'CYw3kZ02Hs0563khs1Fj',
    voiceName: 'Dave'
  },
  {
    voiceId: 'AZnzlk1XvdvUeBnXmlld',
    voiceName: 'Domi'
  },
  {
    voiceId: 'ThT5KcBeYPX3keUQqHPh',
    voiceName: 'Dorthy'
  },
  {
    voiceId: '29vD33N1CtxCmqQRPOHJ',
    voiceName: 'Drew'
  },
  {
    voiceId: 'LcfcDJNUP1GQjkzn1xUU',
    voiceName: 'Emily'
  },
  {
    voiceId: 'g5CIjZEefAph4nQFvHAz',
    voiceName: 'Ethan'
  }
]