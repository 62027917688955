import { useEffect, useState } from "react"
import { LoginCreds } from "../utilities/loginCreds"
import { apiURL } from "../utilities/URLs"

import '../css/CheckoutComplete.css'
import { useNavigate } from "react-router-dom"

export default function CheckoutComplete() {
  const navigate = useNavigate()
  const [userInteractions, setUserInteractions] = useState(false)

  useEffect(() => {
    const userInteractions = localStorage.getItem('interactions')
    const matchSlots = localStorage.getItem('matchSlots')

    if(userInteractions) {
      setUserInteractions(true)
      const interactionInfo = {
        devToken: LoginCreds.devToken,
        userInteractions: userInteractions
      }
      const formData = new FormData()
      formData.append('interactionInfo', JSON.stringify(interactionInfo))
  
      fetch(`${apiURL}/addUserInteractions?isDating=1`, {
        method: 'PUT',
        body: formData
      })
      .then((res) => res.json())
      .then(() => {
        LoginCreds.userInteractions += userInteractions
        localStorage.removeItem('interactions')
      })
    } else if(matchSlots) {
      const matchSlotsInfo = {
        devToken: LoginCreds.devToken,
        matchSlots: matchSlots
      }
      const formData = new FormData()
      formData.append('matchSlotsInfo', JSON.stringify(matchSlotsInfo))

      fetch(`${apiURL}/addUserMatchSlots`, {
        method: 'POST',
        body: formData
      })
      .then((res) => res.json())
      .then(() => {
        LoginCreds.matchSlots += matchSlots
        localStorage.removeItem('matchSlots')
      })
    }
  }, [])

  return (
    <div className='checkoutCompleteContainer'>
      <h1 className='checkoutCompleteHeader'>Checkout Complete!</h1>
      <p className='checkoutCompleteText'>{userInteractions ? 'User Interactions' : 'Match Slots'} Have Been Added To Your Account!</p>
      <button className='checkoutCompleteButton' onClick={() => navigate('/ProfilePage')}>Back To Profile</button>
    </div>
  )
}