import { useEffect, useState } from 'react'
import { LoginCreds } from '../utilities/loginCreds'
import { apiURL } from '../utilities/URLs'
import { useLocation, useNavigate } from 'react-router-dom'
import { Characters } from '../utilities/Characters'
import {
  ResetLoginCreds,
  SetLoginCreds,
  formatNumber,
  isValidEmail,
  isValidUserName,
} from '../utilities/Extensions'
import {
  CharacterTile,
  MyCharacter,
  MyUserPersona,
  UserPersonaTile,
} from '../utilities/CharacterTiles'
import {
  MatchSlots,
  setCurrentUserInteraction,
  SubscriptionTiers,
  UserInteractions,
} from '../utilities/Stripe'

import '../css/ProfilePage.css'
import '../css/ProfilePage-Mobile.css'

import arrowIcon from '../assets/icons/arrow.png'
import backButton from '../assets/icons/backButton.png'
import blankProfileImage from '../assets/icons/BlankProfilePicture.png'
import { CharacterSelfiesModal } from '../utilities/PopUpWindows'

function ProfilePage() {
  const navigate = useNavigate()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const index = params.get('shop')

  const [userPersona, setUserPersona] = useState(null)
  const [customizedCharacters, setCustomizedCharacters] = useState([])
  const [likedCharacters, setLikedCharacters] = useState([])
  const [favoriteCharacters, setFavoriteCharacters] = useState([])
  const [matchedCharacters, setMatchedCharacters] = useState([])
  const [blockedCharacters, setBlockedCharacters] = useState([])

  const [subTier, setSubTier] = useState(LoginCreds.subTier)

  const [error, setError] = useState('')
  const [selectedSettingsSectionButton, setSelectedSettingsSectionButton] =
    useState(index ? 'Subscriptions' : 'User Profile')

  useEffect(() => {
    setCustomizedCharacters(Characters.customizedCharacters)
    setLikedCharacters(
      [...Characters.allCharacters, ...Characters.userPersonas].filter(
        (character) =>
          LoginCreds.userLikes.includes(
            character.characterId
              ? character.characterId
              : character.userPersonaId
          )
      )
    )
    setFavoriteCharacters(
      [...Characters.allCharacters, ...Characters.userPersonas].filter(
        (character) =>
          LoginCreds.userFavorites.includes(
            character.characterId
              ? character.characterId
              : character.userPersonaId
          )
      )
    )
    setMatchedCharacters(
      [...Characters.allCharacters, ...Characters.userPersonas].filter(
        (character) =>
          LoginCreds.userMatched.includes(
            character.characterId
              ? character.characterId
              : character.userPersonaId
          )
      )
    )
    setBlockedCharacters(
      [...Characters.allCharacters, ...Characters.userPersonas].filter(
        (character) =>
          LoginCreds.userBlocked.includes(
            character.characterId
              ? character.characterId
              : character.userPersonaId
          )
      )
    )

    if (LoginCreds.userPersonaId !== null) {
      fetch(
        `${apiURL}/getUserPersona?devToken=${LoginCreds.devToken}&userPersonaId=${LoginCreds.userPersonaId}`
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.response.error) setError(data.response.error)
          else setUserPersona(data.response.userPersona)
        })
    }
  }, [])

  const setLoginCreds = (accountInfo) => {
    SetLoginCreds(accountInfo)

    setError('Account Updated!')
    setTimeout(() => {
      setError('')
    }, 2000)
  }

  function SettingsSectionButton({ title, id }) {
    return (
      <button
        key={id}
        onClick={() => setSelectedSettingsSectionButton(id)}
        className={
          selectedSettingsSectionButton === id
            ? 'profileSelectedSettingsSectionButton'
            : 'profileSettingsSectionButton'
        }
      >
        {title}
      </button>
    )
  }

  function UserProfile() {
    const [userName, setUserName] = useState(LoginCreds.userName)
    const [email, setEmail] = useState(LoginCreds.email)
    const [password, setPassword] = useState(LoginCreds.password)
    const [profileImage, setProfileImage] = useState(LoginCreds.profileImage)
    const [profileImagePreview, setProfileImagePreview] = useState(LoginCreds.profileImage)
    const [accountError, setAccountError] = useState('')
    const [notificationPermission, setNotificationPermission] = useState(Notification.permission)

    const handleImageChange = (e) => {
      setProfileImage(e.target.files[0])
      setProfileImagePreview(URL.createObjectURL(e.target.files[0]))
    }

    const handleUpdateAccount = () => {
      if (userName !== '' && (LoginCreds.isGoogleAccount === 0 ? (email !== '' && password !== '') : true) && profileImage !== null) {
        if (!isValidEmail(email)) {
          setAccountError('Invalid Email')
          setTimeout(() => {
            setAccountError('')
          }, 2000)
        } else if (!isValidUserName(userName)) {
          setAccountError('Invalid UserName')
          setTimeout(() => {
            setAccountError('')
          }, 2000)
        } else {
          setAccountError('Updating Account...')
          const accountInfo = {
            isGoogleAccount: LoginCreds.isGoogleAccount,
            devToken: LoginCreds.devToken,
            userName: userName,
            email: email,
            password: password,
            profileImage: LoginCreds.profileImage,
          }

          const formData = new FormData()
          formData.append('accountInfo', JSON.stringify(accountInfo))
          formData.append('userProfileImage', profileImage)

          fetch(`${apiURL}/updateAccount`, {
            method: 'PUT',
            body: formData,
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.response.error) {
                setAccountError(data.response.error)
                setTimeout(() => {
                  setAccountError('')
                }, 2000)
              } else setLoginCreds(data.response.updatedAccount)
            })
        }
      } else {
        setAccountError('Missing Fields')
        setTimeout(() => {
          setAccountError('')
        }, 2000)
      }
    }

    const handleLogOut = () => {
      localStorage.removeItem('devToken')
      ResetLoginCreds()
      navigate('/')
    }

    const handleToggleNotifications = () => {
      if (Notification.permission === 'default') {
        Notification.requestPermission().then(permission => {
          setNotificationPermission(permission)
        })
      }
    }

    return (
      <div>
        <div className='profileSettingsSectionHeader'>User Profile</div>
        <div className='profileUserInformationContainer'>
          <div className='profilePageImage'>
            {profileImagePreview !== '' ? (
              <img src={profileImagePreview} alt='profileImagePreview' />
            ) : (
              <img src={blankProfileImage} alt='blankProfileImage' />
            )}
            <input
              className='uploadProfileImageButton'
              type='file'
              accept='image/png, image/jpeg'
              id='characterImage'
              onChange={(e) => handleImageChange(e)}
            />
          </div>
          <div className='profilePageTextBox'>
            <p className='settingsLabel'>USERNAME</p>
            <input
              type='text'
              id='userName'
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              placeholder='UserName'
            />
          </div>
          {LoginCreds.isGoogleAccount === 0 && (
            <>
              <div className='profilePageTextBox'>
                <p className='settingsLabel'>EMAIL</p>
                <input
                  type='text'
                  id='email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder='Email'
                />
              </div>
              <div className='profilePageTextBox'>
                <p className='settingsLabel'>PASSWORD</p>
                <input
                  type='password'
                  id='password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder='Password'
                />
              </div>
            </>
          )}
          <button onClick={() => handleToggleNotifications()}>
            {notificationPermission === 'granted' ? 'Disable Nofications' : 'Enable Notifications'}
          </button>
          <div className='profilePageButtons'>
            <button onClick={() => handleUpdateAccount()}>
              Update Account
            </button>
            <button onClick={() => handleLogOut()}>Log Out</button>
          </div>
          {accountError !== '' && <p>{accountError}</p>}
        </div>
      </div>
    )
  }

  function UserPersona() {
    return (
      <div>
        <div className='profileSettingsSectionHeader'>My Persona</div>
        <div className='profileUserInformationContainer'>
          {userPersona !== null ? (
            <MyUserPersona
              userPersona={userPersona}
              setUserPersona={setUserPersona}
            />
          ) : (
            <div className='noCharacterContainer'>
              <h2>No User Persona</h2>
              <button onClick={() => navigate('/CreatePersona')}>
                Create Persona
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }

  function MyCharacters() {
    const [charactersPerPage, setCharactersPerPage] = useState(18)
    const [currentPage, setCurrentPage] = useState(1)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth)
    }

    useEffect(() => {
      window.addEventListener('resize', updateWindowWidth)

      return () => {
        window.removeEventListener('resize', updateWindowWidth)
      }
    }, [])

    useEffect(() => {
      const charactersPerRow = Math.floor(windowWidth / 500)
      var charactersPerPageCalculated = charactersPerRow * 2
      if (window.innerWidth < 480) {
        if (charactersPerPageCalculated < 6) charactersPerPageCalculated = 6
      } else {
        charactersPerPageCalculated = 4
      }
      setCharactersPerPage(charactersPerPageCalculated)
    }, [windowWidth])

    const indexOfLastCharacter = currentPage * charactersPerPage
    const indexOfFirstCharacters = indexOfLastCharacter - charactersPerPage
    const currentCharacters = customizedCharacters.slice(
      indexOfFirstCharacters,
      indexOfLastCharacter
    )
    const totalPages = Math.ceil(
      customizedCharacters.length / charactersPerPage
    )

    return (
      <div>
        <div className='profileSettingsSectionHeader'>My Characters</div>
        <div className='profileUserInformationContainer'>
          <div className='profileCharacters'>
            {customizedCharacters.length === 0 ? (
              <div className='noCharacterContainer'>
                <h2>No Customized Characters</h2>
              </div>
            ) : (
              <div>
                <div className='all-character-profile-grid'>
                  {currentCharacters.map((characterObj) => (
                    <MyCharacter
                      key={characterObj.characterId}
                      character={characterObj}
                    />
                  ))}
                </div>
                <div className='pagination'>
                  <img
                    className={`leftArrow ${
                      currentPage === 1 ? 'disabled-arrow' : ''
                    }`}
                    onClick={() =>
                      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
                    }
                    src={arrowIcon}
                    alt='arrowIcon'
                  />
                  <span style={{ color: '#292a34'}}>{`Page ${currentPage} of ${totalPages}`}</span>
                  <img
                    className={`rightArrow ${
                      currentPage === totalPages ? 'disabled-arrow' : ''
                    }`}
                    onClick={() =>
                      setCurrentPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages)
                      )
                    }
                    src={arrowIcon}
                    alt='arrowIcon'
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  function MyLikedCharacters() {
    const [charactersPerPage, setCharactersPerPage] = useState(18)
    const [currentPage, setCurrentPage] = useState(1)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth)
    }

    useEffect(() => {
      window.addEventListener('resize', updateWindowWidth)

      return () => {
        window.removeEventListener('resize', updateWindowWidth)
      }
    }, [])

    useEffect(() => {
      const charactersPerRow = Math.floor(windowWidth / 500)
      var charactersPerPageCalculated = charactersPerRow * 2
      if (window.innerWidth < 480) {
        if (charactersPerPageCalculated < 6) charactersPerPageCalculated = 6
      } else {
        charactersPerPageCalculated = 4
      }

      setCharactersPerPage(charactersPerPageCalculated)
    }, [windowWidth])

    const indexOfLastCharacter = currentPage * charactersPerPage
    const indexOfFirstCharacters = indexOfLastCharacter - charactersPerPage
    const currentCharacters = likedCharacters.slice(
      indexOfFirstCharacters,
      indexOfLastCharacter
    )
    const totalPages = Math.ceil(likedCharacters.length / charactersPerPage)

    return (
      <div>
        <div className='profileSettingsSectionHeader'>My Liked Characters</div>
        <div className='profileUserInformationContainer'>
          <div className='profileCharacters'>
            {likedCharacters.length === 0 ? (
              <div className='noCharacterContainer'>
                <h2>No Liked Characters</h2>
              </div>
            ) : (
              <div>
                <div className='all-character-profile-grid'>
                  {currentCharacters.map((characterObj) =>
                    characterObj.hasOwnProperty('characterId') ? (
                      <CharacterTile
                        key={characterObj.characterId}
                        character={characterObj}
                        characterVariant={1}
                        setLikedCharacters={setLikedCharacters}
                        setFavoriteCharacters={setFavoriteCharacters}
                      />
                    ) : (
                      <UserPersonaTile
                        key={characterObj.userPersonaId}
                        character={characterObj}
                        setLikedCharacters={setLikedCharacters}
                        setFavoriteCharacters={setFavoriteCharacters}
                      />
                    )
                  )}
                </div>
                <div className='pagination'>
                  <img
                    className={`leftArrow ${
                      currentPage === 1 ? 'disabled-arrow' : ''
                    }`}
                    onClick={() =>
                      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
                    }
                    src={arrowIcon}
                    alt='arrowIcon'
                  />
                  <span classname={{ color: '#292a34'}}>{`Page ${currentPage} of ${totalPages}`}</span>
                  <img
                    className={`rightArrow ${
                      currentPage === totalPages ? 'disabled-arrow' : ''
                    }`}
                    onClick={() =>
                      setCurrentPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages)
                      )
                    }
                    src={arrowIcon}
                    alt='arrowIcon'
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  function MyMatchedCharacters() {
    const [charactersPerPage, setCharactersPerPage] = useState(18)
    const [currentPage, setCurrentPage] = useState(1)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [showCharacterSelfies, setShowCharacterSelfies] = useState(false)
    const [selfieCharacterId, setSelfieCharacterId] = useState('')

    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth)
    }

    useEffect(() => {
      window.addEventListener('resize', updateWindowWidth)

      return () => {
        window.removeEventListener('resize', updateWindowWidth)
      }
    }, [])

    useEffect(() => {
      const charactersPerRow = Math.floor(windowWidth / 500)
      var charactersPerPageCalculated = charactersPerRow * 2
      if (window.innerWidth < 480) {
        if (charactersPerPageCalculated < 6) charactersPerPageCalculated = 6
      } else {
        charactersPerPageCalculated = 4
      }

      setCharactersPerPage(charactersPerPageCalculated)
    }, [windowWidth])

    const indexOfLastCharacter = currentPage * charactersPerPage
    const indexOfFirstCharacters = indexOfLastCharacter - charactersPerPage
    const currentCharacters = matchedCharacters.slice(
      indexOfFirstCharacters,
      indexOfLastCharacter
    )
    const totalPages = Math.ceil(matchedCharacters.length / charactersPerPage)

    const handleSelectCharacterSelfies = (characterId) => {
      setSelfieCharacterId(characterId)
      setShowCharacterSelfies(true)
    }

    return (
      <div>
        <div className='profileSettingsSectionHeader'>
          My Matched Characters
        </div>
        <div className='profileCharactersInfoContainer'>
          <div className='profileCharacters'>
            {matchedCharacters.length === 0 ? (
              <div className='noCharacterContainer'>
                <h2>No Matched Characters</h2>
              </div>
            ) : (
              <div>
                <div className='all-character-profile-grid'>
                  {currentCharacters.map((characterObj) =>
                    characterObj.hasOwnProperty('characterId') ? (
                      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '1rem'}}>
                      <CharacterTile
                        key={characterObj.characterId}
                        character={characterObj}
                        characterVariant={1}
                        setLikedCharacters={setLikedCharacters}
                        setFavoriteCharacters={setFavoriteCharacters}
                      />
                      <button onClick={() => handleSelectCharacterSelfies(characterObj.characterId)}>Character Selfies</button>
                      </div>
                    ) : (
                      <UserPersonaTile
                        key={characterObj.userPersonaId}
                        character={characterObj}
                        setLikedCharacters={setLikedCharacters}
                        setFavoriteCharacters={setFavoriteCharacters}
                      />
                    )
                  )}
                </div>
                <div className='pagination'>
                  <img
                    className={`leftArrow ${
                      currentPage === 1 ? 'disabled-arrow' : ''
                    }`}
                    onClick={() =>
                      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
                    }
                    src={arrowIcon}
                    alt='arrowIcon'
                  />
                  <span style={{ color: '#292a34'}}>{`Page ${currentPage} of ${totalPages}`}</span>
                  <img
                    className={`rightArrow ${
                      currentPage === totalPages ? 'disabled-arrow' : ''
                    }`}
                    onClick={() =>
                      setCurrentPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages)
                      )
                    }
                    src={arrowIcon}
                    alt='arrowIcon'
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <CharacterSelfiesModal
          showPopUp={showCharacterSelfies}
          setShowPopUp={setShowCharacterSelfies}
          characterId={selfieCharacterId}
        />
      </div>
    )
  }

  function MyFavoriteCharacters() {
    const [charactersPerPage, setCharactersPerPage] = useState(18)
    const [currentPage, setCurrentPage] = useState(1)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth)
    }

    useEffect(() => {
      window.addEventListener('resize', updateWindowWidth)

      return () => {
        window.removeEventListener('resize', updateWindowWidth)
      }
    }, [])

    useEffect(() => {
      const charactersPerRow = Math.floor(windowWidth / 500)
      var charactersPerPageCalculated = charactersPerRow * 2
      if (window.innerWidth < 480) {
        if (charactersPerPageCalculated < 6) charactersPerPageCalculated = 6
      } else {
        charactersPerPageCalculated = 4
      }

      setCharactersPerPage(charactersPerPageCalculated)
    }, [windowWidth])

    const indexOfLastCharacter = currentPage * charactersPerPage
    const indexOfFirstCharacters = indexOfLastCharacter - charactersPerPage
    const currentCharacters = favoriteCharacters.slice(
      indexOfFirstCharacters,
      indexOfLastCharacter
    )
    const totalPages = Math.ceil(favoriteCharacters.length / charactersPerPage)

    return (
      <div>
        <div className='profileSettingsSectionHeader'>
          My Favorite Characters
        </div>
        <div className='profileCharactersInfoContainer'>
          <div className='profileCharacters'>
            {favoriteCharacters.length === 0 ? (
              <div className='noCharacterContainer'>
                <h2>No Favorite Characters</h2>
              </div>
            ) : (
              <div>
                <div className='all-character-profile-grid'>
                  {currentCharacters.map((characterObj) =>
                    characterObj.hasOwnProperty('characterId') ? (
                      <CharacterTile
                        key={characterObj.characterId}
                        character={characterObj}
                        characterVariant={1}
                        setLikedCharacters={setLikedCharacters}
                        setFavoriteCharacters={setFavoriteCharacters}
                      />
                    ) : (
                      <UserPersonaTile
                        key={characterObj.userPersonaId}
                        character={characterObj}
                        setLikedCharacters={setLikedCharacters}
                        setFavoriteCharacters={setFavoriteCharacters}
                      />
                    )
                  )}
                </div>
                <div className='pagination'>
                  <img
                    className={`leftArrow ${
                      currentPage === 1 ? 'disabled-arrow' : ''
                    }`}
                    onClick={() =>
                      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
                    }
                    src={arrowIcon}
                    alt='arrowIcon'
                  />
                  <span style={{ color: '#292a34'}}>{`Page ${currentPage} of ${totalPages}`}</span>
                  <img
                    className={`rightArrow ${
                      currentPage === totalPages ? 'disabled-arrow' : ''
                    }`}
                    onClick={() =>
                      setCurrentPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages)
                      )
                    }
                    src={arrowIcon}
                    alt='arrowIcon'
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  function BlockedCharacters() {
    const [charactersPerPage, setCharactersPerPage] = useState(18)
    const [currentPage, setCurrentPage] = useState(1)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth)
    }

    useEffect(() => {
      window.addEventListener('resize', updateWindowWidth)

      return () => {
        window.removeEventListener('resize', updateWindowWidth)
      }
    }, [])

    useEffect(() => {
      const charactersPerRow = Math.floor(windowWidth / 500)
      var charactersPerPageCalculated = charactersPerRow * 2
      if (window.innerWidth < 480) {
        if (charactersPerPageCalculated < 6) charactersPerPageCalculated = 6
      } else {
        charactersPerPageCalculated = 4
      }

      setCharactersPerPage(charactersPerPageCalculated)
    }, [windowWidth])

    const indexOfLastCharacter = currentPage * charactersPerPage
    const indexOfFirstCharacters = indexOfLastCharacter - charactersPerPage
    const currentCharacters = blockedCharacters.slice(
      indexOfFirstCharacters,
      indexOfLastCharacter
    )
    const totalPages = Math.ceil(blockedCharacters.length / charactersPerPage)

    return (
      <div>
        <div className='profileSettingsSectionHeader'>Blocked Characters</div>
        <div className='profileCharactersInfoContainer'>
          <div className='profileCharacters'>
            {blockedCharacters.length === 0 ? (
              <div className='noCharacterContainer'>
                <h2>No Blocked Characters</h2>
              </div>
            ) : (
              <div>
                <div className='all-character-profile-grid'>
                  {currentCharacters.map((characterObj) =>
                    characterObj.hasOwnProperty('characterId') ? (
                      <CharacterTile
                        key={characterObj.characterId}
                        character={characterObj}
                        characterVariant={1}
                        setLikedCharacters={setLikedCharacters}
                        setFavoriteCharacters={setFavoriteCharacters}
                      />
                    ) : (
                      <UserPersonaTile
                        key={characterObj.userPersonaId}
                        character={characterObj}
                        setLikedCharacters={setLikedCharacters}
                        setFavoriteCharacters={setFavoriteCharacters}
                      />
                    )
                  )}
                </div>
                <div className='pagination'>
                  <img
                    className={`leftArrow ${
                      currentPage === 1 ? 'disabled-arrow' : ''
                    }`}
                    onClick={() =>
                      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
                    }
                    src={arrowIcon}
                    alt='arrowIcon'
                  />
                  <span style={{ color: '#292a34'}}>{`Page ${currentPage} of ${totalPages}`}</span>
                  <img
                    className={`rightArrow ${
                      currentPage === totalPages ? 'disabled-arrow' : ''
                    }`}
                    onClick={() =>
                      setCurrentPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages)
                      )
                    }
                    src={arrowIcon}
                    alt='arrowIcon'
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  function Subscriptions() {
    const handleSubscription = (subTier) => {
      const subInfo = {
        devToken: LoginCreds.devToken,
        subTier: subTier,
        subAmount: SubscriptionTiers[subTier].subAmount,
      }
      const formData = new FormData()
      formData.append('subInfo', JSON.stringify(subInfo))

      fetch(`${apiURL}/subscribeToTier`, {
        method: 'POST',
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.response.error) setError(data.response.error)
          else LoginCreds.subTier = data.response.subInfo
          setSubTier(data.response.subInfo)
        })
    }

    const handleDeleteSubscription = () => {
      fetch(`${apiURL}/deleteSubscription?devToken=${LoginCreds.devToken}`, {
        method: 'DELETE',
      }).then(() => {
        LoginCreds.subTier = 0
        setSubTier(0)
      })
    }

    return (
      <div style={{ width: '100%' }}>
        <div className='profileSubscriptionContainer'>
          <div className='profileSettingsSectionHeader'>User Interactions</div>
          <p style={{ textAlign: 'center' }}>
            Current User Interactions:{' '}
            {formatNumber(LoginCreds.userDatingInteractions)}
          </p>
          <div className='subscriptionsContainer'>
            <div className='subscriptionContainer'>
              <h1>Basic</h1>
              <ul className='subscriptionList'>
                <li>
                  {UserInteractions[0].userInteractions} Additional Interactions
                </li>
              </ul>
              <button
                className='subscriptionButton'
                onClick={() => {
                  setCurrentUserInteraction(UserInteractions[0])
                  navigate('/Checkout')
                }}
              >
                ${(UserInteractions[0].amount / 100).toFixed(2)}
              </button>
            </div>
            <div className='subscriptionContainer'>
              <h1>Plus</h1>
              <ul className='subscriptionList'>
                <li>
                  {UserInteractions[1].userInteractions} Additional Interactions
                </li>
              </ul>
              <button
                className='subscriptionButton'
                onClick={() => {
                  setCurrentUserInteraction(UserInteractions[1])
                  navigate('/Checkout')
                }}
              >
                ${(UserInteractions[1].amount / 100).toFixed(2)}
              </button>
            </div>
            <div className='subscriptionContainer'>
              <h1>Pro</h1>
              <ul className='subscriptionList'>
                <li>
                  {UserInteractions[2].userInteractions} Interactions Per Month
                </li>
              </ul>
              <button
                className='subscriptionButton'
                onClick={() => {
                  setCurrentUserInteraction(UserInteractions[2])
                  navigate('/Checkout')
                }}
              >
                ${(UserInteractions[2].amount / 100).toFixed(2)}
              </button>
            </div>
          </div>
          <div className='profileSettingsSectionHeader'>Match Slots</div>
          <div className='subscriptionsContainer'>
            <div className='subscriptionContainer'>
              <h1>Basic</h1>
              <ul className='subscriptionList'>
                <li>{MatchSlots[0].matchSlots} extra match slots</li>
              </ul>
              <button
                className='subscriptionButton'
                onClick={() => {
                  setCurrentUserInteraction(MatchSlots[0])
                  navigate('/Checkout')
                }}
              >
                ${(UserInteractions[2].amount / 100).toFixed(2)}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='profileContainer'>
      <div className='profileSettingsSectionsListContainer'>
        <img
          onClick={() => navigate('/')}
          className='profileSettingsSectionsBackButton'
          src={backButton}
          alt='backButton'
        />
        <div className='profileSettingsSectionsList'>
          <p className='profileSettingsSectionsListHeader'>USER SETTINGS</p>
          <SettingsSectionButton title={'User Profile'} id={'User Profile'} />
          <SettingsSectionButton title={'Shop'} id={'Subscriptions'} />
          <SettingsSectionButton
            title={'My Favorite Characters'}
            id={'My Favorite Characters'}
          />
          <SettingsSectionButton title={'My Matched Characters'} id={'My Matched Characters'} />
          <SettingsSectionButton
            title={'Blocked Characters'}
            id={'Blocked Characters'}
          />
        </div>
      </div>
      <div
        className={
          selectedSettingsSectionButton !== 'Subscriptions'
            ? 'profileSettingsSectionContainer'
            : 'profileSubscriptionSectionContainer'
        }
      >
        {selectedSettingsSectionButton === 'User Profile' ? (
          <UserProfile />
        ) : selectedSettingsSectionButton === 'My Persona' ? (
          <UserPersona />
        ) : selectedSettingsSectionButton === 'My Characters' ? (
          <MyCharacters />
        ) : selectedSettingsSectionButton === 'My Liked Characters' ? (
          <MyLikedCharacters />
        ) : selectedSettingsSectionButton === 'Subscriptions' ? (
          <Subscriptions />
        ) : selectedSettingsSectionButton === 'My Favorite Characters' ? (
          <MyFavoriteCharacters />
        ) : selectedSettingsSectionButton === 'Blocked Characters' ? (
          <BlockedCharacters />
        ) : (
          <MyMatchedCharacters />
        )}
      </div>
    </div>
  )
}

export default ProfilePage
