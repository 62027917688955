import React, { useState } from 'react'
import { apiURL } from '../../utilities/URLs'

export default function FineTuningDemo() {
  const [messages, setMessages] = useState([])
  const [inputText, setInputText] = useState('')
  const [sendingMessage, setSendingMessage] = useState(false)
  const [error, setError] = useState('')
  const [isFineTuningMode, setIsFineTuningMode] = useState(true)

  const handleSend = async () => {
    if (inputText.trim() === '') return

    setSendingMessage(true)

    const userMessage = { text: inputText, sender: 'user' }
    setMessages([...messages, userMessage])
    setInputText('')

    try {
      let responseURL = `${apiURL}/sendFineTuneMessage`
      if (!isFineTuningMode) {
        responseURL = `${apiURL}/sendEmbeddingMessage`
      }

      const fineTuningResponse = await fetch(`${responseURL}?message=${inputText}`)
      const fineTuningData = await fineTuningResponse.json()

      setSendingMessage(false)
      if (fineTuningData.response.error) {
        setError(fineTuningData.response.error)
        setTimeout(() => {
          setError('')
        }, 2000)
      } else {
        setMessages(prevMessages => [...prevMessages, { text: fineTuningData.response.message, sender: 'ai' }])
      }
    } catch (error) {
      setSendingMessage(false)
      setError('Failed to send message')
      setTimeout(() => {
        setError('')
      }, 2000)
    }
  }

  return (
    <div style={styles.pageContainer}>
      <div style={styles.chatContainer}>
        <div style={styles.messagesContainer}>
          {messages.map((msg, index) => (
            <div
              key={index}
              style={msg.sender === 'user' ? styles.userMessage : styles.aiMessage}
            >
              {msg.text}
            </div>
          ))}
        </div>
        {sendingMessage && <div style={styles.sendingMessage}>Sending message...</div>}
        <div style={styles.inputContainer}>
          <input
            type="text"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            style={styles.input}
            disabled={sendingMessage}
          />
          <button onClick={handleSend} style={styles.sendButton} disabled={sendingMessage}>Send</button>
          <button onClick={() => setIsFineTuningMode(true)} style={isFineTuningMode ? styles.activeButton : styles.toggleButton}>Fine-Tuning</button>
          <button onClick={() => setIsFineTuningMode(false)} style={!isFineTuningMode ? styles.activeButton : styles.toggleButton}>Embedding</button>
        </div>
      </div>
    </div>
  )
}

const styles = {
  pageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    backgroundColor: '#2c2c2c'
  },
  chatContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '80vh',
    width: '50vw',
    backgroundColor: '#1e1e1e',
    border: '1px solid #ccc',
    borderRadius: '10px',
    fontFamily: 'Arial, sans-serif',
    overflow: 'hidden'
  },
  messagesContainer: {
    flex: 1,
    padding: '10px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    backgroundColor: '#2c2c2c'
  },
  userMessage: {
    alignSelf: 'flex-end',
    backgroundColor: '#DCF8C6',
    padding: '10px',
    borderRadius: '10px',
    maxWidth: '60%',
    wordWrap: 'break-word'
  },
  aiMessage: {
    alignSelf: 'flex-start',
    backgroundColor: '#F1F0F0',
    padding: '10px',
    borderRadius: '10px',
    maxWidth: '60%',
    wordWrap: 'break-word'
  },
  sendingMessage: {
    alignSelf: 'center',
    marginBottom: '10px',
    color: '#888',
    fontStyle: 'italic'
  },
  inputContainer: {
    display: 'flex',
    padding: '10px',
    borderTop: '1px solid #ccc',
    backgroundColor: '#1e1e1e'
  },
  input: {
    flex: 1,
    padding: '10px',
    borderRadius: '10px',
    border: '1px solid #ccc',
    marginRight: '10px',
    backgroundColor: '#333',
    color: '#fff'
  },
  sendButton: {
    padding: '10px 20px',
    marginRight: '10px',
    borderRadius: '10px',
    border: 'none',
    backgroundColor: '#007BFF',
    color: 'white',
    cursor: 'pointer',
    disabled: {
      backgroundColor: '#ccc',
      cursor: 'not-allowed'
    }
  },
  toggleButton: {
    padding: '10px 20px',
    borderRadius: '10px',
    border: 'none',
    backgroundColor: '#444',
    color: 'white',
    cursor: 'pointer',
    marginRight: '10px',
  },
  activeButton: {
    padding: '10px 20px',
    borderRadius: '10px',
    border: 'none',
    backgroundColor: '#007BFF',
    color: 'white',
    cursor: 'pointer',
    marginRight: '10px',
  }
}