import { useLocation, useNavigate } from "react-router-dom"
import { useParams } from 'react-router-dom'
import { useEffect, useRef, useState } from "react"
import { checkAudioRecordingTimeout, checkDecibelLevel, exportBuffer, recordingTimeout, setCheckAudioRecordingTimeout, setRecordingTimeout } from "../utilities/audio"
import { apiURL } from "../utilities/URLs"
import { LoginCreds } from "../utilities/loginCreds"
import { mapEmotionToEmoji } from "../utilities/CharacterTraits"
import { CharacterEmotions } from "../utilities/CharacterTraits"
import { Characters } from "../utilities/Characters"
import { ConfirmReport, LoseScreen, NoMoreInteractions, RegisterForAnAccount, SessionClosed, SignInToLike, WinScreen } from "../utilities/PopUpWindows.js"
import CharacterModal from "./CharacterModal.js"
import Recorder from 'recorder-js'

import '../css/CharacterPage.css'
import '../css/CharacterPage-Mobile.css'

import userPfp from '../assets/icons/BlankProfilePicture.png'
import backButton from '../assets/icons/backButton.png'
import clickSound from '../assets/sounds/clickSound.mp3'
import heartMeterIcon from '../assets/icons/heartMeterIcon.png'

function CharacterPage() {
  const { characterId } = useParams()

  const navigate = useNavigate()
  const location = useLocation()

  const [currentCharacter, setCurrentCharacter] = useState(location.state ? location.state.character : null)
  const [currentCharacterLikeCount, setCurrentCharacterLikeCount] = useState(location.state ? (location.state.character.characterLikeCount ? location.state.character.characterLikeCount : location.state.character.userPersonaLikeCount) : 0)
  if(currentCharacterLikeCount === undefined)
    setCurrentCharacterLikeCount(0)
  const [isDatingGame, setIsDatingGame] = useState(location.state ? location.state.isDatingGame : null)

  const [loadingCharacter, setLoadingCharacter] = useState(true)
  const [sessionToken, setSessionToken] = useState('')

  const [userMessage, setUserMessage] = useState('')
  const [messages, setMessages] = useState([])
  const [emotion, setCharacterEmotion] = useState('')
  const [emotionCode, setEmotionCode] = useState('')

  const [loading, setLoading] = useState('')
  const [error, setError] = useState('')
  const [isRecording, setRecording] = useState(false)

  const [microphoneStream, setMicrophoneStream] = useState(null)
  const [recorder, setRecorder] = useState(null)
  const [analyser, setAnalyser] = useState(null)

  const audioRef = useRef(null)

  const [userSentAMessage, setUserSentAMessage] = useState(false)
  const timerRef = useRef(null)

  const [characterModalIsOpen, setCharacterModalIsOpen] = useState(false)

  const [interactions, setInteractions] = useState(0)

  const [noMoreInteractions, setNoMoreInteractions] = useState(false)
  const [registerForAnAccount, setRegisterForAnAccount] = useState(false)
  const [signInToLike, setSignInToLike] = useState(false)
  const [showReport, setShowReport] = useState(false)

  const [showMaxSessionsReached, setShowMaxSessionsReached] = useState(false)

  const [characterHearts, setCharacterHearts] = useState(1)
  const [showWin, setShowWin] = useState(false)
  const [showLose, setShowLose] = useState(false)
  const [currentSelfieIds, setCurrentSelfieIds] = useState([])
  const [showSessionClosed, setShowSessionClosed] = useState(false)

  let _sessionToken = ''

  const handleReport = (e) => {
    e.stopPropagation()
    setShowReport(true)
  }

  useEffect(() => {
    if(userSentAMessage === true)
      clearTimeout(timerRef.current)
  }, [userSentAMessage])

  useEffect(() => {
    return () => {
      closeSession(_sessionToken)
    };
  }, []);

  useEffect(() => {
    if(interactions >= 500) {
      localStorage.setItem('freeInteractions', interactions)
      setRegisterForAnAccount(true)
    }
  }, [interactions])

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      fetch(`${apiURL}/sendTrigger?token=${_sessionToken}&triggerName=userIdle&isDating=1`)
      .then((res) => res.json())
      .then((data) => {
        if(data.response.error) {
          if(data.response.error === 'No More Interactions Left For The Month') {
            setNoMoreInteractions(true)
          } else {
            setError(data.response.error)
          }
        }
        else {
          if(data.response.emotion) {
            setEmotionCode(data.response.emotion.code)
            setCharacterEmotion(mapEmotionToEmoji(data.response.emotion))
          }
          else {
            setEmotionCode('')
            setCharacterEmotion('')
          }

          if(LoginCreds.devToken !== '')
            LoginCreds.userDatingInteractions -= data.response.messages.length
          setInteractions(interactions-data.response.messages.length)

          handleAddMessage(data.response.messages, 0)
        }
      })
    }, 30000)

    const fetchData = async () => {
      try {
        let character
        setLoadingCharacter(true)

        if(LoginCreds.devToken === '') {
          const storedInteractions = localStorage.getItem('freeInteractions')
          const initialInteractions = storedInteractions !== null ? parseInt(storedInteractions) : 0
          setInteractions(initialInteractions)
        }

        character = Characters.allCharacters.find((char) => characterId === char.characterId || characterId === char.userPersonaId)

        if (character === undefined)
          navigate('/')
        else {
          setCurrentCharacter(character)
          setCurrentCharacterLikeCount(character.characterLikeCount ? character.characterLikeCount : character.userPersonaLikeCount)
        }

        fetch(`${apiURL}/getSessionTokenDatingCharacter?userName=Guest&accountToken=${LoginCreds.devToken}&characterId=${character.characterId}&audio=1&emotion=1&isDating=1`)
        .then((res) => res.json())
        .then((data) => {
          if(data.response.error) {
            if(data.response.error === 'No More Interactions Left For The Month') {
              setNoMoreInteractions(true)
            } else if(data.response.error === 'Maximum Number Of Sessions Reached') {
              setShowMaxSessionsReached(true)
            } else {
              setError(data.response.error)
            }
          }
          else {
            _sessionToken = data.response.sessionToken
            setSessionToken(data.response.sessionToken)

            fetch(`${apiURL}/sendTrigger?token=${data.response.sessionToken}&triggerName=greetings&isDating=1`)
            .then((res) => res.json())
            .then((data) => {
              if(data.response.error) {
                if(data.response.error === 'No More Interactions Left For The Month') {
                  setNoMoreInteractions(true)
                } else if(data.response.error === 'Maximum Number Of Sessions Reached') {
                  setShowMaxSessionsReached(true)
                } else {
                  setError(data.response.error)
                }
              }
              else {
                if(data.response.emotion) {
                  setEmotionCode(data.response.emotion.code)
                  setCharacterEmotion(mapEmotionToEmoji(data.response.emotion))
                }
                else {
                  setEmotionCode('')
                  setCharacterEmotion('')
                }

                if(LoginCreds.devToken !== '')
                  LoginCreds.userDatingInteractions -= data.response.messages.length
                setInteractions(interactions-data.response.messages.length)

                handleAddMessage(data.response.messages, 0)
              }
            })
            setLoadingCharacter(false)
          }
        })
      } catch (error) {
        console.error("An error occurred:", error)
      }
    }

    if(currentCharacter === null)
      fetchData()
    else {
      if(LoginCreds.devToken === '') {
        const storedInteractions = localStorage.getItem('freeInteractions')
        const initialInteractions = storedInteractions !== null ? parseInt(storedInteractions) : 0
        setInteractions(initialInteractions)
      }

      fetch(`${apiURL}/getSessionTokenDatingCharacter?userName=${LoginCreds.userName !== '' ? LoginCreds.userName : 'Guest'}&accountToken=${LoginCreds.devToken}&characterId=${currentCharacter.characterId}&audio=1&emotion=1&isDating=1`)
      .then((res) => res.json())
      .then((data) => {
        if(data.response.error) {
          if(data.response.error === 'No More Interactions Left For The Month') {
            setNoMoreInteractions(true)
          } else if(data.response.error === 'Maximum Number Of Sessions Reached') {
            setShowMaxSessionsReached(true)
          } else {
            setError(data.response.error)
          }
        }
        else {
          _sessionToken = data.response.sessionToken
          setSessionToken(data.response.sessionToken)

          if(LoginCreds.lastCharacterMessage.character === undefined || LoginCreds.lastCharacterMessage.character.characterId !== currentCharacter.characterId) {
            fetch(`${apiURL}/sendTrigger?token=${data.response.sessionToken}&triggerName=greetings&isDating=1`)
            .then((res) => res.json())
            .then((data) => {
              if(data.response.error) {
                if(data.response.error === 'No More Interactions Left For The Month') {
                  setNoMoreInteractions(true)
                } else if(data.response.error === 'Maximum Number Of Sessions Reached') {
                  setShowMaxSessionsReached(true)
                } else {
                  setError(data.response.error)
                }
              }
              else {
                if(data.response.emotion) {
                  setEmotionCode(data.response.emotion.code)
                  setCharacterEmotion(mapEmotionToEmoji(data.response.emotion))
                }
                else {
                  setEmotionCode('')
                  setCharacterEmotion('')
                }

                if(LoginCreds.devToken !== '')
                  LoginCreds.userDatingInteractions -= data.response.messages.length
                setInteractions(interactions-data.response.messages.length)

                handleAddMessage(data.response.messages, 0)
              }
            })
          } else
            setMessages((prevMessages) => [...prevMessages, {characterId: currentCharacter.characterId, message: LoginCreds.lastCharacterMessage.message}])

          setLoadingCharacter(false)
        }
      })
    }

    const initMicrophone = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
        const audioCtx = new (window.AudioContext)()
        const source = audioCtx.createMediaStreamSource(stream)

        const analyser = audioCtx.createAnalyser()
        analyser.fftSize = 2048

        source.connect(analyser)

        const recorder = new Recorder(audioCtx)

        setMicrophoneStream(stream)
        setRecorder(recorder)
        setAnalyser(analyser)
      } catch (error) {
        console.error('Error accessing microphone: ', error)
      }
    }

    initMicrophone()

    return () => {
      if (microphoneStream) microphoneStream.getTracks().forEach((track) => track.stop())
    }
  }, [])


  useEffect(() => {
    document.documentElement.scrollTop = document.documentElement.scrollHeight
    var messageBody = document.getElementById('chat_box')
    if(messageBody)
      messageBody.scrollTop = messageBody.scrollHeight
  }, [messages])

  const handleSendMessage = () => {
    if(userMessage !== '') {
      setUserSentAMessage(true)
      setUserMessage('')
      setError('')
      setMessages((prevMessages) => [...prevMessages, {characterId: 0, message: userMessage}])
      setLoading('Sending Message . . .')
      fetch(`${apiURL}/sendMessage?message=${userMessage}&token=${sessionToken}&isDating=1`)
      .then((res) => res.json())
      .then(async (data) => {
        if(data.response.error) {
          if(data.response.error === 'No More Interactions Left For The Month')
            setNoMoreInteractions(true)
          else if(data.response.error === 'Session Does Not Exist')
            setShowSessionClosed(true)
          setLoading(data.response.error)
        } else {
          setLoading('')
          if(data.response.emotion) {
            setEmotionCode(data.response.emotion.code)
            setCharacterEmotion(mapEmotionToEmoji(data.response.emotion))
          }
          else {
            setEmotionCode('')
            setCharacterEmotion('')
          }

          if(LoginCreds.devToken !== '') {
            LoginCreds.userDatingInteractions -= data.response.messages.length
          }
          setInteractions(interactions-data.response.messages.length)

          if(isDatingGame) {
            const addedSelfie = await calculateNewHearts()
            if(!addedSelfie)
              handleAddMessage(data.response.messages, 0)
          } else
            handleAddMessage(data.response.messages, 0)
        }
      })
    } else
      setError('Please Enter A Message')
  }

  const calculateNewHearts = async () => {
    const maxHearts = 5
    const minHearts = 0
    const gainProbability = 0.3 / (characterHearts + 1)
    const loseProbability = 0.1 / (maxHearts - characterHearts + 1)
    const guaranteedGainProbability = characterHearts < 5 ? 0.5 : 0
    const randomValue = Math.random()

    let newHearts = characterHearts

    if (randomValue < gainProbability + guaranteedGainProbability)
      newHearts = Math.min(characterHearts + 1, maxHearts)
    else if (randomValue < gainProbability + guaranteedGainProbability + loseProbability)
      newHearts = Math.max(characterHearts - 1, minHearts)
    else
      newHearts = characterHearts

    let addSelfie = false

    if(newHearts === 5)
      setShowWin(true)
    else if(newHearts === 0)
      setShowLose(true)
    else {
      if(newHearts > characterHearts)
        addSelfie = await getCharacterSelfie()

      setCharacterHearts(newHearts)
    }

    return addSelfie
  }

  const getCharacterSelfie = async () => {
    const characterSelfieResponse = await fetch(`${apiURL}/getRandomCharacterSelfie?characterId=${currentCharacter.characterId}&selfieIds=${currentSelfieIds.join(',')}`)
    const characterSelfieData = await characterSelfieResponse.json()
    if(characterSelfieData.response.error)
      console.error(characterSelfieData.response.error)
    else {
      if(characterSelfieData.response.characterSelfie) {
        setCurrentSelfieIds(prevIds => [...prevIds, characterSelfieData.response.characterSelfie.selfieId])
        setMessages((prevMessages) => [...prevMessages, {characterId: currentCharacter.characterId, message: characterSelfieData.response.characterSelfie.selfieImageURL}, {characterId: currentCharacter.characterId, message: characterSelfieData.response.characterSelfie.selfiePhrase}])
        return true
      } else
        return false
    }
  }

  const closeSession = (sessionToken) => {
    if(LoginCreds.lastCharacterMessage.character)
      LoginCreds.lastCharacterMessage = {}

    if(timerRef)
      clearTimeout(timerRef.current)

    if(LoginCreds.devToken === '')
      localStorage.setItem('freeInteractions', interactions)

    if(sessionToken.length > 0) {
      fetch(`${apiURL}/closeSession?token=${sessionToken}`)
      .then((res) => res.json())
      .then(() => {
        if(LoginCreds.userMatched.includes(currentCharacter.characterId)) {
          LoginCreds.lastCharacterMessage = {character: currentCharacter,
                                            message: 'Hey, where have you been?'}
        }
        navigate('/')
      })
    }
  }

  const handleAddMessage = (newMessages, index) => {
    if(audioRef.current !== null) {
      if(index < newMessages.length) {
        setMessages((prevMessages) => [...prevMessages, newMessages[index]])

        audioRef.current.src = 'data:audio/wav;base64,'+newMessages[index].audioChunk
        audioRef.current.play()
        audioRef.current.onended = () => {
          handleAddMessage(newMessages, index+1)
        }
      }
    }
  }

  const handleKeyEvent = (key) => {
    if(key === 'Enter')
      handleSendMessage()
  }

  const startRecording = () => {
    new Audio(clickSound).play()
    setRecording(true)

    const timeoutId = setTimeout(() => {
      stopRecording()
    }, 10000)

    setRecordingTimeout(timeoutId)

    const checkAudioTimeoutId = setTimeout(() => {
      checkDecibelLevel(analyser, () => {
        autoStopRecording()
      })
    }, 2000)

    setCheckAudioRecordingTimeout(checkAudioTimeoutId)

    recorder.init(microphoneStream)
    recorder.start()
  }

  const stopRecording = async () => {
    new Audio(clickSound).play()
    if(recordingTimeout !== null) {
      clearTimeout(recordingTimeout)
      setRecordingTimeout(null)
    }

    if(checkAudioRecordingTimeout !== null) {
      clearTimeout(checkAudioRecordingTimeout)
      setCheckAudioRecordingTimeout(null)
    }

    setRecording(false)
    const buffer = await recorder.stop()

    const audio = exportBuffer(buffer.buffer[0])

    sendAudioBlob(audio)
  }

  const autoStopRecording = async () => {
    new Audio(clickSound).play()
    if(recordingTimeout !== null) {
      clearTimeout(recordingTimeout)
      setRecordingTimeout(null)
    }

    if(checkAudioRecordingTimeout !== null) {
      clearTimeout(checkAudioRecordingTimeout)
      setCheckAudioRecordingTimeout(null)
    }

    await recorder.stop()
    setRecording(false)
  }

  const sendAudioBlob = async (audio) => {
    setUserSentAMessage(true)
    setLoading('Loading . . .')
    setError('')
    const formData = new FormData()
    formData.append('audio', audio, 'audio.wav')

    fetch(`${apiURL}/sendAudioMessage?token=${sessionToken}&isDating=1`, {
      method: 'POST',
      body: formData
    })
    .then((res) => res.json())
    .then((data) => {
      if(data.response.error) {
        if(data.response.error === 'No More Interactions Left For The Month')
          setNoMoreInteractions(true)
        else if(data.response.error === 'Session Does Not Exist')
          setShowSessionClosed(true)
        setLoading(data.response.error)
      } else {
        setLoading('')
        if(data.response.emotion) {
          setEmotionCode(data.response.emotion.code)
          setCharacterEmotion(mapEmotionToEmoji(data.response.emotion))
        }
        else {
          setEmotionCode('')
          setCharacterEmotion('')
        }

        if(LoginCreds.devToken !== '')
          LoginCreds.userDatingInteractions -= data.response.messages.length
        setInteractions(interactions-data.response.messages.length)

        setMessages((prevMessages) => [...prevMessages, {characterId: 0, message: data.response.userMessage.message}])
        handleAddMessage(data.response.messages, 0)
      }
    })
  }

  const closeSessionAndEditCharacter = () => {
    fetch(`https://midlayer.ndsprj.com/closeSession?token=${sessionToken}`)
    .then((res) => res.json())
    .then((data) => navigate('/EditCharacter', { state: { character: currentCharacter } }))
  }

  const handleCloseModal = () => {
    setCharacterModalIsOpen(false)
  }

  return(
    <>
    {showMaxSessionsReached ? (
      <div className='characterPageContainer'>
        <p className="fetchingCharacterText">Maximum Sessions Reached. Please Try Again Later.</p>
      </div>
    ) : (
      <div className='characterPageContainer'>
        {!loadingCharacter ? (
          <div className='characterPageChatContainer'>
            <div className='backButtonDiv'>
              <img className='backButton' src={backButton} alt='backButton' onClick={() => navigate('/')}/>
            </div>
            <div className='characterPageChatHeader'>
              {isDatingGame && (
                <div className='characterPageHeartMeterContainer'>
                  {Array.from({ length: characterHearts}).map((index) => (
                    <img key={index} className='heartIcon' src={heartMeterIcon} alt='Heart Meter Icon' />
                  ))}
              </div>
              )}
              <img className='characterImage' src={currentCharacter.characterImage ? currentCharacter.characterImage : currentCharacter.userPersonaImage} alt='characterImage'/>
              <button onClick={setCharacterModalIsOpen}>{currentCharacter.characterName ? currentCharacter.characterName : currentCharacter.userPersonaName}</button>
              <p className='characterHeaderDesc'>{currentCharacter.characterDesc ? currentCharacter.characterDesc : currentCharacter.userPersonaDesc}</p>
              {currentCharacter.characterCreatedBy &&
                <div className='characterHeaderCreatedBy'>
                  <p>Created By:</p>
                  <p>{currentCharacter.characterCreatedBy ? currentCharacter.characterCreatedBy : currentCharacter.userPersonaCreatedBy}</p>
                </div>
              }
              
              {emotion !== '' &&
                <p className='emotion'>Current Emotion: {CharacterEmotions[emotionCode]+' '+emotion}</p>
              }
              {(currentCharacter.devToken !== undefined && currentCharacter.devToken === LoginCreds.devToken) &&
                <button onClick={() => closeSessionAndEditCharacter()}>Edit Character</button>
              }
            </div>
            <div className='characterPageBox'>
              <div id='chat_box' className='characterPageChatBox'>
              {messages.length > 0 &&
                messages.map((message, index) => (
                  message.characterId !== 0 ? (
                    <div key={index} className='characterMessage'>
                      <div className='messageImage'>
                        <img src={currentCharacter.characterImage ? currentCharacter.characterImage : currentCharacter.userPersonaImage} alt='avatarImg' />
                        <p>{currentCharacter.characterName ? currentCharacter.characterName : currentCharacter.userPersonaName}</p>
                      </div>
                      {message.message.startsWith('https') ? (
                        <img className='characterMessageImage' src={message.message} />
                      ) : (
                        <p className='characterMessageText'>{message.message}</p>
                      )}
                    </div>
                  ) : (
                    <div key={index} className='userMessage'>
                      <p className='userMessageText'>{message.message}</p>
                      <div className='messageImage'>
                        <img src={LoginCreds.profileImage !== '' ? LoginCreds.profileImage : userPfp} alt='avatarImg' />
                        <p>{LoginCreds.userName !== '' ? LoginCreds.userName : 'Guest'}</p>
                      </div>
                    </div>
                  )
                ))
              }
            </div>
            <audio ref={audioRef} />
            {error !== '' && (
              <p style={{fontSize:'20px', color: 'red', margin: '0'}}>{error}</p>
            )}
            {loading !== '' && (
              <p style={{fontSize:'20px', margin: '0'}}>{loading}</p>
            )}
            <div className='characterPageInputBox'>
              <input
                type='text'
                id='messageInput'
                value={userMessage}
                onKeyDown={(e) => handleKeyEvent(e.key)}
                onChange={(e) => setUserMessage(e.target.value)}
                placeholder='Enter message...'
              />
              <div className='characterPageActions'>
                <button disabled={userMessage === ''} className={userMessage === '' ? 'disabledButton' : 'submitButton'} onClick={handleSendMessage}>Send</button>

                <button
                  className='nonSelectedButton'
                  onClick={() => {
                    isRecording ? stopRecording() : startRecording()
                  }}
                  >
                  {isRecording ? 'Stop Mic' : 'Start Mic'}
                </button>
              </div>
            </div>
          </div>
        </div>
        ) : (
          <div>
            {error !== '' ? (
              <p style={{ fontSize: '20px', color: 'red', margin: '0' }}>{error}</p>
            ) : (
              <p className="fetchingCharacterText">Fetching Character . . .</p>
            )}
          </div>
        )}
      </div>
    )}
      <CharacterModal
        currentCharacter={currentCharacter}
        characterModalIsOpen={characterModalIsOpen}
        handleCloseModal={handleCloseModal}
        handleReport={handleReport}
      ></CharacterModal>
      <NoMoreInteractions
        showPopUp={noMoreInteractions}
        setShowPopUp={setNoMoreInteractions}
      />
      <RegisterForAnAccount
        showPopUp={registerForAnAccount}
        setShowPopUp={setRegisterForAnAccount}
      />
      <SignInToLike
        showPopUp={signInToLike}
        setShowPopUp={setSignInToLike}
      />
      <ConfirmReport
        showPopUp={showReport}
        setShowPopUp={setShowReport}
        character={currentCharacter}
      />
      <WinScreen
        showPopUp={showWin}
        setShowPopUp={setShowWin}
        setIsDatingGame={setIsDatingGame}
        character={currentCharacter}
        navigate={navigate}
      />
      <LoseScreen
        showPopUp={showLose}
        setShowPopUp={setShowLose}
        character={currentCharacter}
      />
      <SessionClosed
        showPopUp={showSessionClosed}
        character={currentCharacter}
        navigate={navigate}
      />
    </>
  )
}

export default CharacterPage

/*
<div className='characterLikeCount'>
                <p><b>{formatNumber(currentCharacterLikeCount)}</b></p>
                {LoginCreds.userLikes.includes(currentCharacter.characterId ? currentCharacter.characterId : currentCharacter.userPersonaId) ? (
                  <img onClick={() => {handleRemoveLike()}} src={likedIcon} alt='likedIcon'/>
                ) : (
                  <img onClick={() => {LoginCreds.devToken !== '' ? handleSendLike() : setSignInToLike(true)}} src={notLikedIcon} alt='likedIcon'/>
                )}
              </div>

              const handleSendLike = () => {
    fetch(`${apiURL}/sendLike?devToken=${LoginCreds.devToken}&characterId=${currentCharacter.characterId ? currentCharacter.characterId : currentCharacter.userPersonaId}`)
    .then((res) => res.json())
    .then((data) => {
      currentCharacter.characterLikeCount ? currentCharacter.characterLikeCount = data.response.updatedLikeCount : currentCharacter.userPersonaLikeCount = data.response.updatedLikeCount
      setCurrentCharacterLikeCount(currentCharacter.characterLikeCount ? currentCharacter.characterLikeCount : currentCharacter.userPersonaLikeCount)

      if(Characters.allCharacters.length > 0) {
        const characterIndex = Characters.allCharacters.findIndex((char) => char.characterId ? char.characterId === (currentCharacter.characterId ? currentCharacter.characterId : currentCharacter.userPersonaId) : char.userPersonaId === (currentCharacter.characterId ? currentCharacter.characterId: currentCharacter.userPersonaId))
        Characters.allCharacters[characterIndex].characterLikeCount = data.response.updatedLikeCount
      }

      LoginCreds.userLikes.push(currentCharacter.characterId ? currentCharacter.characterId : currentCharacter.userPersonaId)
    })
  }

  const handleRemoveLike = () => {
    fetch(`${apiURL}/removeLike?devToken=${LoginCreds.devToken}&characterId=${currentCharacter.characterId ? currentCharacter.characterId : currentCharacter.userPersonaId}`)
    .then((res) => res.json())
    .then((data) => {
      currentCharacter.characterLikeCount ? currentCharacter.characterLikeCount = data.response.updatedLikeCount : currentCharacter.userPersonaLikeCount = data.response.updatedLikeCount
      setCurrentCharacterLikeCount(currentCharacter.characterLikeCount ? currentCharacter.characterLikeCount : currentCharacter.userPersonaLikeCount)

      if(Characters.allCharacters.length > 0) {
        const characterIndex = Characters.allCharacters.findIndex((char) => char.characterId ? char.characterId === (currentCharacter.characterId ? currentCharacter.characterId : currentCharacter.userPersonaId) : char.userPersonaId === (currentCharacter.characterId ? currentCharacter.characterId: currentCharacter.userPersonaId))
        Characters.allCharacters[characterIndex].characterLikeCount = data.response.updatedLikeCount
      }

      const index = LoginCreds.userLikes.findIndex((item) => item === currentCharacter.characterId ? currentCharacter.characterId : currentCharacter.userPersonaId)
      LoginCreds.userLikes.splice(index, 1)
    })
  }
*/
