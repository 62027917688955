export var LoginCreds = {
  isGoogleAccount: 0,
  email: '',
  userName: '',
  password: '',
  profileImage: '', 
  devToken: '',
  userPersonaId: null,
  userInteractions: 0,
  userDatingInteractions: 500,
  matchSlots: 5,
  userLikes: [],
  userFavorites: [],
  userMatched: [],
  userBlocked: [],
  subTier: 0,
  userDatingUnlockPoints: 0,
  lastCharacterMessage: {}
}