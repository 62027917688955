import React, { useEffect, useRef, useState } from 'react'
import { ReactMic } from 'react-mic-recorder'
import { apiURL } from '../../utilities/URLs'

export default function CustomELVoice() {
  const [customVoices, setCustomVoices] = useState([])
  const [selectedCustomVoiceId, setSelectedCustomVoiceId] = useState('')
  const [voiceText, setVoiceText] = useState('')
  const audioRef = useRef(null)
  const [gettingText, setGettingText] = useState(false)

  const handleListenToVoice = async () => {
    if(selectedCustomVoiceId.length > 0 && voiceText.length > 0) {
      setGettingText(true)
      const listenToVoiceResponse = await fetch(`${apiURL}/listenToVoice?voiceId=${selectedCustomVoiceId}&voiceText=${voiceText}`)
      const listenToVoiceData = await listenToVoiceResponse.json()
      setGettingText(false)
      if(listenToVoiceData.response.error)
        console.error(listenToVoiceData.response.error)
      else {
        audioRef.current.src = 'data:audio/wav;base64,'+listenToVoiceData.response.audioChunk
        audioRef.current.play()
      }
    }
  }

  useEffect(() => {
    const handleGetCustomVoices = async () => {
      const customVoicesResponse = await fetch(`${apiURL}/getCustomVoices`)
      const customVoicesData = await customVoicesResponse.json()
      if(customVoicesData.response.error)
        console.error(customVoicesData.response.error)
      else
        setCustomVoices(customVoicesData.response.customVoices)
    }

    handleGetCustomVoices()
  }, [])

  return (
    <div style={{ maxWidth: '600px', margin: 'auto', textAlign: 'center', paddingTop: '20px' }}>
      <h2>Select A Custom Voice</h2>
      <select value={selectedCustomVoiceId} onChange={(e) => setSelectedCustomVoiceId(e.target.value)} style={styles.select}>
          <option value=''>Select a voice</option>
          {customVoices.map((customVoice) => (
            <option key={customVoice.voice_id} value={customVoice.voice_id}>
              {customVoice.name}
            </option>
          ))}
        </select>
      <div style={{ marginTop: '20px' }}>
        <input
          type='text'
          placeholder='Enter Voice Dialogue'
          value={voiceText}
          onChange={(e) => setVoiceText(e.target.value)}
          style={styles.input}
        />
      </div>
      <button style={styles.uploadButton} onClick={handleListenToVoice}>
        Get Custom Dialogue
      </button>
      {gettingText && (
        <p>Getting Dialogue...</p>
      )}
      <audio ref={audioRef} />
    </div>
  )
}

const styles = {
  button: {
    fontSize: '16px',
    padding: '10px 20px',
    margin: '10px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
    outline: 'none',
  },
  audio: {
    width: '100%',
    marginTop: '20px',
  },
  uploadButton: {
    fontSize: '16px',
    padding: '10px 20px',
    margin: '20px 0',
    backgroundColor: '#28a745',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
    outline: 'none',
  },
  input: {
    padding: '10px',
    fontSize: '16px',
    width: '300px',
    maxWidth: '100%',
    border: '1px solid #ccc',
    borderRadius: '5px',
    outline: 'none',
  },
}

/*
const [audioFile, setAudioFile] = useState(null)
  const [voiceName, setVoiceName] = useState('')
const handleUpload = async () => {
    console.log('UPLOAD')
    if (!audioFile || !voiceName) {
      console.error('No audio file recorded.')
      return
    }

    const form = new FormData()
    form.append('voice', audioFile, 'test.mp3')

    const uploadVoiceResponse = await fetch(`${apiURL}/uploadCustomVoice?voiceName=${voiceName}`, {
      method: 'POST',
      body: form
    })
    const uploadVoiceData = await uploadVoiceResponse.json()
  }
const handleNameChange = (e) => {
    setVoiceName(e.target.value)
  }
const [isRecording, setIsRecording] = useState(false)
  const [blobURL, setBlobURL] = useState('')
const startRecording = () => {
    setIsRecording(true)
  }

  const stopRecording = () => {
    setIsRecording(false)
  }

  const onData = (recordedBlob) => {
    console.log('Chunk of real-time data is: ', recordedBlob)
  }

  const onStop = (recordedBlob) => {
    console.log('Recording stopped: ', recordedBlob)
    setBlobURL(recordedBlob.blobURL)
    setAudioFile(recordedBlob.blob)
  }
  <h2>Record Your Voice</h2>
      <ReactMic
        record={isRecording}
        className='sound-wave'
        onStop={onStop}
        onData={onData}
        mimeType='audio/mp3'
      />
      {!isRecording ? (
        <button style={styles.button} onClick={startRecording}>
          Start Recording
        </button>
      ) : (
        <button style={styles.button} onClick={stopRecording}>
          Stop Recording
        </button>
      )}
      {blobURL && <audio controls src={blobURL} style={styles.audio} />}
*/