import { useEffect, useRef, useState } from 'react'
import { Characters } from '../utilities/Characters'
import { ToastContainer } from 'react-toastify'
import { UserPersonaTile } from '../utilities/CharacterTiles'

import arrowIcon from '../assets/icons/arrow.png'
import categoryArrow from '../assets/icons/categoryArrow.png'

import Footer from './Footer'
import '../css/Home.css'
import '../css/Home-Mobile.css'
import ChatubeHeader from './ChatubeHeader'
import LeftSideBar from './LeftSideBar'

function Community() {
  const [userPersonas, setUserPersonas] = useState([])
  const [categoryUserPersonas, setCategories] = useState([])
  const [selectedButton, setSelectedButton] = useState(-1)
  const [userPersonasPerPage, setCharactersPerPage] = useState(18)
  const [currentPage, setCurrentPage] = useState(() => {
    return parseInt(localStorage.getItem('currentCommunityPage')) || 1
  })
  const [refreshCommunity, setRefreshCommunity] = useState(false)

  useEffect(() => {
    setUserPersonas(Characters.userPersonas)  
    setCategories(Characters.userPersonaCategoryButtons)
  }, [])

  useEffect(() => {
    setSelectedButton(-1)
    setRefreshCommunity(false)
    setCurrentPage(1)
  }, [refreshCommunity])

  function ChatubeUserPersonas() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth)
    }

    useEffect(() => {
      window.addEventListener('resize', updateWindowWidth)

      return () => {
        window.removeEventListener('resize', updateWindowWidth)
      }
    }, [])

    useEffect(() => {
      const userPersonasPerRow = Math.floor(windowWidth / 250)
      let userPersonasPerPageCalculated = userPersonasPerRow * 2
      if (userPersonasPerPageCalculated < 6)
        userPersonasPerPageCalculated = 6

      setCharactersPerPage(userPersonasPerPageCalculated)
    }, [windowWidth])

    useEffect(() => {
      localStorage.setItem('currentCommunityPage', currentPage)
    }, [currentPage])

    const indexOfLastPersona = currentPage * userPersonasPerPage
    const indexOfFirstPersonas = indexOfLastPersona - userPersonasPerPage
    const currentUserPersonas = userPersonas.slice(indexOfFirstPersonas, indexOfLastPersona)
    const totalPages = Math.ceil(userPersonas.length / userPersonasPerPage)

    return (
      <div className='characters'>
        {userPersonas.length === 0 ? (
          <p>Loading...</p>
        ) : (
          <div className='all-character-grid-container'>
            <div className='all-character-grid'>
              {currentUserPersonas.map((userPersonaObj) => (
                <UserPersonaTile
                  key={userPersonaObj.userPersonaId}
                  character={userPersonaObj}
                  setUserPersonas={setUserPersonas}
                />
              ))}
            </div>
            <div className='pagination'>
              <img className={`leftArrow ${currentPage === 1 ? 'disabled-arrow' : ''}`} onClick={() => setCurrentPage((prevPage) => Math.max(prevPage-1, 1))} src={arrowIcon} alt='arrowIcon'/>
              <span>{`Page ${currentPage} of ${totalPages}`}</span>
              <img className={`rightArrow ${currentPage === totalPages ? 'disabled-arrow' : ''}`} onClick={() => setCurrentPage((prevPage) => Math.min(prevPage+1, totalPages))} src={arrowIcon} alt='arrowIcon'/>
            </div>
          </div>
        )}
      </div>
    )
  }

  function CategoryButton({ title, id }) {
    return (
      <button onClick={() => setSelectedButton(id)} className={selectedButton === id ? 'selectedCategoryButton' : 'categoryButton'}>{title}</button>
    )
  }

  function CategoryButtons() {
    return (
      <div className='categoryButtons'>
        {categoryUserPersonas.map((category, index) => (
          <CategoryButton
            key={category.categoryId}
            title={category.categoryName.charAt(0).toUpperCase() + category.categoryName.slice(1)}
            id={index}
          />
        ))}
      </div>
    )
  }

  function SelectedCategory() {
    const charactersRef = useRef(null)
    const [showLeftArrow, setShowLeftArrow] = useState(false)
    const [showRightArrow, setShowRightArrow] = useState(true)
    const characterHeader = categoryUserPersonas[selectedButton].categoryName+' Category'

    useEffect(() => {
      const { current } = charactersRef
      if(current) {
        current.addEventListener('scroll', updateArrowVisibility, { passive: true })

        updateArrowVisibility()
    
        return () => {
          current.removeEventListener('scroll', updateArrowVisibility)
        }
      }
    }, [])

    const updateArrowVisibility = () => {
      const { current } = charactersRef
      if(current) {
        const maxScrollLeft = current.scrollWidth - current.clientWidth

        setShowLeftArrow(current.scrollLeft > 0)
        setShowRightArrow(current.scrollLeft < maxScrollLeft)
      }
    }

    const scrollCharacters = (direction) => {
      const { current } = charactersRef
      if(current) {
        const scrollAmount = Math.floor((current.clientWidth / 205)) * 205
        const newScrollPosition = direction === 'left' ? current.scrollLeft - scrollAmount : current.scrollLeft + scrollAmount

        current.scrollTo({
          left: newScrollPosition,
          behavior: 'smooth'
        })
      }
    }

    return (
      <div id='moga-characters' className='categoryCharacters'>
        <h3>{characterHeader.charAt(0).toUpperCase() + characterHeader.slice(1)}</h3>
        <div className='categoryArrowDiv'>
          {showLeftArrow && (
            <img className='categoryLeftArrow' onClick={() => scrollCharacters('left')} src={categoryArrow} alt='categoryArrow' />
          )}
          {categoryUserPersonas[selectedButton].userPersonas.length === 0 ? (
            <p className='loadingText'>Loading...</p>
          ) : (
            <div className='character-grid' ref={charactersRef}>
              {categoryUserPersonas[selectedButton].userPersonas.map((userPersonaObj) => (
                <UserPersonaTile
                  key={userPersonaObj.userPersonaId}
                  character={userPersonaObj}
                  setUserPersonas={setUserPersonas}
                />
              ))}
            </div>
          )}
          {showRightArrow && (
            <img className='categoryRightArrow' onClick={() => scrollCharacters('right')} src={categoryArrow} alt='categoryArrow' />
          )}
        </div>
      </div>
    )
  }

  return(
    <div className='App'>
      <header className='App-header'>
        <ChatubeHeader />
        <LeftSideBar 
          setRefreshCommunity={setRefreshCommunity}
        />
        {selectedButton !== -2 && (
          <>
            <CategoryButtons />
            {selectedButton !== -1 && (
              <SelectedCategory />
            )}
          </>
        )}
        <ChatubeUserPersonas />
        <ToastContainer />
        <Footer />
      </header>
    </div>
  )
}

export default Community
