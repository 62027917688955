import { useNavigate } from 'react-router-dom'
import { LoginCreds } from '../utilities/loginCreds'

import homeIcon from '../assets/icons/homeIcon.png'
import createIcon from '../assets/icons/createIcon.png'
import favoritedIcon from '../assets/icons/favoritedIcon.png'
import heartIcon from '../assets/icons/heartMeterIcon.png'
import blockedIcon from '../assets/icons/blockedIcon.png'

function LeftSideBar({ setRefreshHome, setRefreshCommunity, showFavorites, setShowFavorites, showMatched, setShowMatched, showBlocked, setShowBlocked }) {
  const navigate = useNavigate()

  const handleNavigate = (index) => {
    switch(index) {
      case 0:
        if(setRefreshHome)
          setRefreshHome(true)
        localStorage.removeItem('currentHomePage')
        navigate('/')
        break
      case 1:
        if(LoginCreds.devToken === '')
          navigate('/Login')
        else
          navigate('/CreateCharacter')
        break
      case 2:
        setShowFavorites(true)
        setShowMatched(false)
        setShowBlocked(false)
        break
      case 3:
        setShowMatched(true)
        setShowFavorites(false)
        setShowBlocked(false)
        break
      case 4:
        setShowBlocked(true)
        setShowFavorites(false)
        setShowMatched(false)
        break
      default:
        navigate('/')
        break
    }
  }

  function LeftSideIcon({ icon, name, index, selectedIcon }) {
    return (
      <div onClick={() => handleNavigate(index)} className={selectedIcon ? 'selectedIcon' : 'icon'}>
        <img src={icon} alt='icon' />
        <p>{name}</p>
      </div>
    )
  }

  return (
    <div className='leftSideBar'>
      <LeftSideIcon
        icon={homeIcon}
        name={'Home'}
        index={0}
        selectedIcon={false}
      />
      <LeftSideIcon
        icon={createIcon}
        name={'Create'}
        index={1}
        selectedIcon={false}
      />
      <LeftSideIcon
        icon={favoritedIcon}
        name={'Favorites'}
        index={2}
        selectedIcon={showFavorites}
      />
      <LeftSideIcon
        icon={heartIcon}
        name={'Matched'}
        index={3}
        selectedIcon={showMatched}
      />
      <LeftSideIcon
        icon={blockedIcon}
        name={'Blocked'}
        index={4}
        selectedIcon={showBlocked}
      />
    </div>
  )
}

export default LeftSideBar