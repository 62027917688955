import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { LoginCreds } from "../utilities/loginCreds"
import { Characters } from "../utilities/Characters"
import '../css/ChatubeHeader.css'
import '../css/ChatubeHeader-Mobile.css'

import searchIcon from '../assets/icons/searchIcon.png'
import blankProfileImage from '../assets/icons/BlankProfilePicture.png'
import chatCitiesLogo from '../assets/icons/chatCitiesLogo.png'
import inboxIcon from '../assets/icons/inbox.png'
import { NotificationPopUp } from "./Notification"

import lockedIcon from '../assets/icons/lockedIcon.png'

function ChatubeHeader() {
  const navigate = useNavigate()
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [numOfMessages, setNumOfMessages] = useState(0)
  const [showNotification, setShowNotification] = useState(false)
  const [showSearchBar, setShowSearchBar] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      if(Object.keys(LoginCreds.lastCharacterMessage).length > 0) {
        setNumOfMessages(1)
        setShowNotification(true)
      }
    }, 5000)
  })

  const handleSetSearchQuery = (value) => {
    if(value.length === 0)
      setSearchResults([])

    setSearchQuery(value)
  }

  const handleSearchForCharacter = (value) => {
    setSearchQuery(value)

    var searchResults = []

    if(value === '') {
      setSearchResults([])
      return
    }

    Characters.allCharacters.forEach((character) => {
      if(character.characterName.toLowerCase().startsWith(value.toLowerCase()))
        searchResults.push(character)
    })

    Characters.tags.forEach((tag) => {
      if(value.toLowerCase() === tag.tagName) {
        Characters.allCharacters.forEach((character) => {
          if(character.tagIds.includes(tag.tagId))
            searchResults.push(character)
        })
      }
    })

    setSearchResults(searchResults)
  }

  function SearchView() {
    return (
      <div className='searchResults'>
        {searchResults.map((result, index) => {
          return(
            result.isUnlocked === 0 ? (
                <div key={index} className='characterLockedSearch'>
                  <img className='lockedHeaderIcon' src={lockedIcon} />
                  <img className='searchLockedImage' src={result.characterImage} alt='characterImage'/>
                  <p className='searchLockedText'>{result.characterName}</p>
                </div>
            ) : (
              <div onClick={() => navigate('/CharacterPage', { state: { character: result, characterVariant: result.characterIsPublic ? 1 : 0 } })} key={index} className='characterSearch'>
                <img className='searchImage' src={result.characterImage} alt='characterImage'/>
                <p className='searchText'>{result.characterName}</p>
              </div>
            )
          )
        })}
      </div>
    )
  }

  return (
    <div className='chatTubeHeader'>
      <img onClick={() => navigate('/')} className='chatubeLogo' src={chatCitiesLogo} alt='chatCitiesLogo' />
      <div className='rightSideButtons'>
        {showSearchBar && (
          <div className='searchArea'>
            <input
              type='text'
              id='searchQuery'
              value={searchQuery}
              onChange={(e) => handleSearchForCharacter(e.target.value)}
              placeholder='Search Character...'
            />
            {searchResults.length > 0 &&
              <SearchView />
            }
          </div>
        )}
        <img id="search-icon" className='rightSideIcon' onClick={() => setShowSearchBar(!showSearchBar)} src={searchIcon} alt='searchIcon'/>
        {LoginCreds.userName !== '' ? (
          <div className='loggedInText'>
            <p>Welcome, <strong>{LoginCreds.userName}</strong>!</p>
            {LoginCreds.profileImage !== '' ? (
              <img onClick={() => navigate('/ProfilePage')} src={LoginCreds.profileImage} alt='userProfileImage' />
            ) : (
              <img onClick={() => navigate('/ProfilePage')} src={blankProfileImage} alt='blankProfileImage'/>
            )}
          </div>
        ) : (
          <div className='loginSignUpButtons'>
            <button className='loginButton' onClick={() => navigate('/login')}>Log In</button>
            <button className='signUpButton' onClick={() => navigate('/signUp')}>Sign Up</button>
          </div>
        )}
        {LoginCreds.devToken.length > 0 && (
          <div className="inboxWrapper">
          {LoginCreds.devToken.length > 0 && (
            <img
              onClick={() => navigate('/Inbox')}
              className="inboxIcon"
              src={inboxIcon}
              alt="Inbox Icon"
            />
          )}
          {numOfMessages > 0 && (
            <div className="messageBadge">
              {numOfMessages}
            </div>
          )}
        </div>
        )}
      </div>
      <NotificationPopUp
        showNotification={showNotification}
      />
    </div>
  )
}

export default ChatubeHeader
