import { useEffect, useState } from 'react'
import '../../css/LessonPlans/LessonPlansHome.css'
import { apiURL } from '../../utilities/URLs'
import { useNavigate } from 'react-router-dom'

import likeIcon from '../../assets/icons/likedIcon.png'
import favIcon from '../../assets/icons/notFavoritedIcon.png'
import ccuniversity from '../../assets/icons/ccuniversity.png'

export default function LessonPlansHome() {
  const navigate = useNavigate()

  const [fetchingLessonPlans, setFetchingLessonPlans] = useState(true)
  const [lessonPlans, setLessonPlans] = useState([])
  const [error, setError] = useState('')

  useEffect(() => {
    const handleGetLessonPlans = async () => {
      const lessonPlansResponse = await fetch(`${apiURL}/getLessonPlans`)
      const lessonPlansData = await lessonPlansResponse.json()
      if(lessonPlansData.response.error) {
        setError(lessonPlansData.response.error)
        setTimeout(() => {
          setError('')
        }, 4000)
      } else {
        setLessonPlans(lessonPlansData.response.lessonPlans)
        setFetchingLessonPlans(false)
      }
    }

    handleGetLessonPlans()
  }, [])

  const handleDeleteLessonPlan = async (e, lessonPlanId) => {
    e.stopPropagation()
    const deleteLessonResponse = await fetch(`${apiURL}/deleteLessonPlan?lessonPlanId=${lessonPlanId}`, {
      method: 'DELETE'
    })
    const deleteLessonData = await deleteLessonResponse.json()
    if(deleteLessonData.response.error) {
      setError(deleteLessonData.response.error)
      setTimeout(() => {
        setError('')
      }, 4000)
    } else
      setLessonPlans(prevLessonPlans => prevLessonPlans.filter(lessonPlan => lessonPlan.lessonPlanId !== lessonPlanId))
  }

  return (
    <div className='lessonPlansHome'>
      <div className='lessonPlansHeaderContainer'>
        <img className='lessonPlansLogo' src={ccuniversity} />
        <h1 className='lessonPlansHeader'>Lesson Plans</h1>
      </div>
      <div className='lessonPlansContainer'>
        <div className='lessonPlans'>
          {fetchingLessonPlans ? (
            <p className='noLessonsFound'>Getting Lesson Plans...</p>
          ) : (
            lessonPlans.length > 0 ? (
              lessonPlans.map((lessonPlan) => (
                <div onClick={() => navigate('/LessonPlanChat', { state: lessonPlan })} key={lessonPlan.lessonPlanId} className='lessonPlan'>
                  <p className='lessonName'>{lessonPlan.lessonName}</p>
                  <p className='lessonDesc'>{lessonPlan.characterName}</p>
                  <img className='lessonCharacterImageURL' src={lessonPlan.characterImageURL} />
                  <div className='lessonBottomBar'>
                    <img className='lessonLikeIcon' src={likeIcon}/>
                    <button onClick={(e) => handleDeleteLessonPlan(e, lessonPlan.lessonPlanId)} className='deleteLessonPlanButton'>Delete</button>
                    <img className='lessonLikeIcon' src={favIcon}/>
                  </div>
                  
                </div>
              ))
            ) : (
              <p className='noLessonsFound'>No Lesson Plans.</p>
            )
          )}
        </div>
        <button onClick={() => navigate('/CreateLessonPlan')} className='createLessonPlanButton'>Create Lesson Plan</button>
      </div>
    </div>
  )
}