import { useNavigate } from 'react-router-dom'
import '../css/Notification.css'
import { LoginCreds } from '../utilities/loginCreds'
import { useEffect } from 'react'

export function NotificationPopUp({ showNotification }) {
  const navigate = useNavigate()

  useEffect(() => {
    const handleShowNotifiation = () => {
      const notification = new Notification(`You received a message from ${LoginCreds.lastCharacterMessage.character.characterName}`, {
        body: LoginCreds.lastCharacterMessage.message
      })
    }
    if(showNotification)
      handleShowNotifiation()
  }, [showNotification])

  return (
    <>
      {showNotification && (
        <div onClick={() => navigate('/CharacterPage', { state: { character: LoginCreds.lastCharacterMessage.character, isDatingGame: false } })} className="notification">
          <img className="notificationImage" src={LoginCreds.lastCharacterMessage.character.characterImage} alt="Character" />
          <div className="notificationContent">
            <span className="notificationName">{LoginCreds.lastCharacterMessage.character.characterName}</span>
            <span className="notificationMessage">{LoginCreds.lastCharacterMessage.message}</span>
          </div>
        </div>
      )}
    </>
  )
}