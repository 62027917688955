import '../css/Inbox.css'
import backButton from '../assets/icons/backButton.png'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Characters } from '../utilities/Characters'
import { LoginCreds } from '../utilities/loginCreds'

export default function Inbox() {
  const navigate = useNavigate()
  const [matchedCharacters, setMatchedCharacters] = useState([])

  useEffect(() => {
    const matched = [...Characters.allCharacters, ...Characters.userPersonas].filter(
      (character) =>
        LoginCreds.userMatched.includes(
          character.characterId
            ? character.characterId
            : character.userPersonaId
        )
    )
    setMatchedCharacters(matched)
  }, [])

  function MessageList({ character }) {
    return (
      <div onClick={() => navigate('/CharacterPage', { state: { character: character, isDatingGame: false } })} className='messageListContainer'>
        <img className='messageListImage' src={character.characterImage} alt='characterImage' />
        <p className='messageListName'>{character.characterName}</p>
      </div>
    )
  }

  return (
    <div className='inboxContainer'>
      <div className='inboxHeader'>
        <img onClick={() => navigate('/')} className='inboxButton' src={backButton} alt='backButton' />
        <p className='inboxHeaderText'>Inbox</p>
      </div>
      <div className='inboxMessageListContainer'>
        {matchedCharacters.length === 0 ? (
          <p>No Matched Characters</p>
        ) : (
          matchedCharacters.map(character => (
            <MessageList key={character.characterId || character.userPersonaId} character={character} />
          ))
        )}
      </div>
    </div>
  )
}
