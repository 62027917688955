import { React } from 'react'
import { useEffect, useState, useRef } from 'react'
import { Characters } from '../utilities/Characters'
import { ToastContainer } from 'react-toastify'
import { CharacterTile, MyCharacter } from '../utilities/CharacterTiles'
import ChatubeHeader from './ChatubeHeader'
import LeftSideBar from './LeftSideBar'
import Footer from './Footer'

import 'react-toastify/dist/ReactToastify.css'
import '../css/Home.css'
import '../css/Home-Mobile.css'

import arrowIcon from '../assets/icons/arrow.png'
import categoryArrow from '../assets/icons/categoryArrow.png'
import { LoginCreds } from '../utilities/loginCreds'

import astrologyIcon from '../assets/icons/astrology.png'
import flirtsIcon from '../assets/icons/flirts.png'
import friendsIcon from '../assets/icons/friends.png'
import wisdomIcon from '../assets/icons/wisdom.png'
import toxicIcon from '../assets/icons/toxic.png'
import { CharacterSelfiesModal } from '../utilities/PopUpWindows'

const categoryImages = {
  Astrology: astrologyIcon,
  Wisdom: wisdomIcon,
  Flirts: flirtsIcon,
  Toxic: toxicIcon,
  Friends: friendsIcon
}

function Home() {
  const [customizedCharacters, setCustomizedCharacters] = useState([])
  const [sortedCharacters, setSortedCharacters] = useState([])
  const [categoryCharacters, setCategories] = useState([])
  const [selectedButton, setSelectedButton] = useState(-1)
  const [loading, setLoading] = useState(true)
  const [refreshHome, setRefreshHome] = useState(false)
  const [charactersPerPage, setCharactersPerPage] = useState(18)
  const [currentPage, setCurrentPage] = useState(() => {
    return parseInt(localStorage.getItem('currentHomePage')) || 1
  })
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [unlockPoints, setUnlockPoints] = useState(LoginCreds.userDatingUnlockPoints)

  const [favoriteCharacters, setFavoriteCharacters] = useState([])
  const [matchedCharacters, setMatchedCharacters] = useState([])
  const [blockedCharacters, setBlockedCharacters] = useState([])
  const [showFavorites, setShowFavorites] = useState(false)
  const [showMatched, setShowMatched] = useState(false)
  const [showBlocked, setShowBlocked] = useState(false)
  
  useEffect(() => {
    setLoading(true)

    setCustomizedCharacters(Characters.customizedCharacters)
    setSortedCharacters(Characters.sortedCharacters)
    setCategories(Characters.characterCategoryButtons)

    setFavoriteCharacters(
      [...Characters.allCharacters, ...Characters.userPersonas].filter(
        (character) =>
          LoginCreds.userFavorites.includes(
            character.characterId
              ? character.characterId
              : character.userPersonaId
          )
      )
    )
    setMatchedCharacters(
      [...Characters.allCharacters, ...Characters.userPersonas].filter(
        (character) =>
          LoginCreds.userMatched.includes(
            character.characterId
              ? character.characterId
              : character.userPersonaId
          )
      )
    )
    setBlockedCharacters(
      [...Characters.allCharacters, ...Characters.userPersonas].filter(
        (character) =>
          LoginCreds.userBlocked.includes(
            character.characterId
              ? character.characterId
              : character.userPersonaId
          )
      )
    )

    setTimeout(() => {
      setLoading(false)
    }, 100)
  }, [])

  useEffect(() => {
    setSelectedButton(-1)
    setRefreshHome(false)
    setCurrentPage(1)
  }, [refreshHome])

  useEffect(() => {
    window.addEventListener('resize', setWindowWidth(window.innerWidth))

    return () => {
      window.removeEventListener('resize', setWindowWidth(window.innerWidth))
    }
  }, [])

  useEffect(() => {
    const charactersPerRow = Math.floor(windowWidth / 255)
    let charactersPerPageCalculated = charactersPerRow * 2
    if (charactersPerPageCalculated < 6)
      charactersPerPageCalculated = 6

    setCharactersPerPage(charactersPerPageCalculated)
  }, [windowWidth])

  useEffect(() => {
    localStorage.setItem('currentHomePage', currentPage)
  }, [currentPage])

  function ChatubeMyCharacters() {
    return (
      <div className='characters'>
        <h3 style={{ color: '#ff2e2e'}}>My Characters</h3>
        {customizedCharacters.length === 0 ? (
          <p className='loadingText'>Loading...</p>
        ) : (
          <div className='character-grid'>
            {customizedCharacters.map((character) => (
              <MyCharacter
                key={character.characterId}
                character={character}
                setCustomizedCharacters={setCustomizedCharacters}
              />
            ))}
          </div>
        )}
      </div>
    )
  }

  function CategoryButton({ title, id }) {
    return (
      <button onClick={() => selectedButton === id ? setSelectedButton(-1) : setSelectedButton(id)} className={selectedButton === id ? 'selectedCategoryButton' : 'categoryButton'}>
        <img className='categoryButtonImage' src={categoryImages[title]} />
        <p>{title}</p>
      </button>
    )
  }

  function CategoryButtons() {
    return (
      <div className='categoryButtons'>
        {categoryCharacters.map((category, index) => (
          <CategoryButton
            key={category.categoryId}
            title={category.categoryName.charAt(0).toUpperCase() + category.categoryName.slice(1)}
            id={index}
          />
        ))}
      </div>
    )
  }

  function SelectedCategory() {
    const charactersRef = useRef(null)
    const [showLeftArrow, setShowLeftArrow] = useState(false)
    const [showRightArrow, setShowRightArrow] = useState(true)
    const characterHeader = categoryCharacters[selectedButton].categoryName+' Category'

    useEffect(() => {
      const { current } = charactersRef
      if(current) {
        current.addEventListener('scroll', updateArrowVisibility, { passive: true })

        updateArrowVisibility()
    
        return () => {
          current.removeEventListener('scroll', updateArrowVisibility)
        }
      }
    }, [])

    const updateArrowVisibility = () => {
      const { current } = charactersRef
      if(current) {
        const maxScrollLeft = current.scrollWidth - current.clientWidth

        setShowLeftArrow(current.scrollLeft > 0)
        setShowRightArrow(current.scrollLeft < maxScrollLeft)
      }
    }

    const scrollCharacters = (direction) => {
      const { current } = charactersRef
      if(current) {
        const scrollAmount = Math.floor((current.clientWidth / 205)) * 205
        const newScrollPosition = direction === 'left' ? current.scrollLeft - scrollAmount : current.scrollLeft + scrollAmount

        current.scrollTo({
          left: newScrollPosition,
          behavior: 'smooth'
        })
      }
    }

    return (
      <div id='moga-characters' className='categoryCharacters'>
        <h3 style={{ color: '#ff2e2e', fontSize: '28px' }}>{characterHeader.charAt(0).toUpperCase() + characterHeader.slice(1)}</h3>
        <div className='categoryArrowDiv'>
          {showLeftArrow && (
            <img className='categoryLeftArrow' onClick={() => scrollCharacters('left')} src={categoryArrow} alt='categoryArrow' />
          )}
          {categoryCharacters[selectedButton].characters.length === 0 ? (
            <p className='loadingText'>Loading...</p>
          ) : (
            <div className='character-grid' ref={charactersRef}>
              {categoryCharacters[selectedButton].characters.map((characterObj) => (
                <CharacterTile
                  key={characterObj.characterId}
                  character={characterObj}
                  characterVariant={0}
                  setSortedCharacters={setSortedCharacters}
                  setCategories={setCategories}
                  selectedButton={selectedButton}
                />
              ))}
            </div>
          )}
          {showRightArrow && (
            <img className='categoryRightArrow' onClick={() => scrollCharacters('right')} src={categoryArrow} alt='categoryArrow' />
          )}
        </div>
      </div>
    )
  }

  function FavoriteCharacters() {
    const charactersRef = useRef(null)
    const [showLeftArrow, setShowLeftArrow] = useState(false)
    const [showRightArrow, setShowRightArrow] = useState(true)
    const characterHeader = 'My Favorite Characters'

    useEffect(() => {
      const { current } = charactersRef
      if(current) {
        current.addEventListener('scroll', updateArrowVisibility, { passive: true })

        updateArrowVisibility()
    
        return () => {
          current.removeEventListener('scroll', updateArrowVisibility)
        }
      }
    }, [])

    const updateArrowVisibility = () => {
      const { current } = charactersRef
      if(current) {
        const maxScrollLeft = current.scrollWidth - current.clientWidth

        setShowLeftArrow(current.scrollLeft > 0)
        setShowRightArrow(current.scrollLeft < maxScrollLeft)
      }
    }

    const scrollCharacters = (direction) => {
      const { current } = charactersRef
      if(current) {
        const scrollAmount = Math.floor((current.clientWidth / 205)) * 205
        const newScrollPosition = direction === 'left' ? current.scrollLeft - scrollAmount : current.scrollLeft + scrollAmount

        current.scrollTo({
          left: newScrollPosition,
          behavior: 'smooth'
        })
      }
    }

    return (
      <div id='moga-characters' className='categoryCharacters'>
        <h3 style={{ color: '#ff2e2e', fontSize: '28px' }}>{characterHeader.charAt(0).toUpperCase() + characterHeader.slice(1)}</h3>
        <div className='categoryArrowDiv'>
          {showLeftArrow && (
            <img className='categoryLeftArrow' onClick={() => scrollCharacters('left')} src={categoryArrow} alt='categoryArrow' />
          )}
          {favoriteCharacters.length === 0 ? (
            <p className='loadingText'>No Favorite Characters</p>
          ) : (
            <div className='character-grid' ref={charactersRef}>
              {favoriteCharacters.map((characterObj) => (
                <CharacterTile
                  key={characterObj.characterId}
                  character={characterObj}
                  characterVariant={1}
                  setFavoriteCharacters={setFavoriteCharacters}
                />
              ))}
            </div>
          )}
          {showRightArrow && (
            <img className='categoryRightArrow' onClick={() => scrollCharacters('right')} src={categoryArrow} alt='categoryArrow' />
          )}
        </div>
      </div>
    )
  }

  function MatchedCharacters() {
    const charactersRef = useRef(null)
    const [showLeftArrow, setShowLeftArrow] = useState(false)
    const [showRightArrow, setShowRightArrow] = useState(true)
    const [showCharacterSelfies, setShowCharacterSelfies] = useState(false)
    const [selfieCharacterId, setSelfieCharacterId] = useState('')
    const characterHeader = 'My Matched Characters'

    useEffect(() => {
      const { current } = charactersRef
      if(current) {
        current.addEventListener('scroll', updateArrowVisibility, { passive: true })

        updateArrowVisibility()
    
        return () => {
          current.removeEventListener('scroll', updateArrowVisibility)
        }
      }
    }, [])

    const updateArrowVisibility = () => {
      const { current } = charactersRef
      if(current) {
        const maxScrollLeft = current.scrollWidth - current.clientWidth

        setShowLeftArrow(current.scrollLeft > 0)
        setShowRightArrow(current.scrollLeft < maxScrollLeft)
      }
    }

    const scrollCharacters = (direction) => {
      const { current } = charactersRef
      if(current) {
        const scrollAmount = Math.floor((current.clientWidth / 205)) * 205
        const newScrollPosition = direction === 'left' ? current.scrollLeft - scrollAmount : current.scrollLeft + scrollAmount

        current.scrollTo({
          left: newScrollPosition,
          behavior: 'smooth'
        })
      }
    }

    const handleSelectCharacterSelfies = (characterId) => {
      setSelfieCharacterId(characterId)
      setShowCharacterSelfies(true)
    }

    return (
      <div id='moga-characters' className='categoryCharacters'>
        <h3 style={{ color: '#ff2e2e', fontSize: '28px' }}>{characterHeader.charAt(0).toUpperCase() + characterHeader.slice(1)}</h3>
        <div className='categoryArrowDiv'>
          {showLeftArrow && (
            <img className='categoryLeftArrow' onClick={() => scrollCharacters('left')} src={categoryArrow} alt='categoryArrow' />
          )}
          {matchedCharacters.length === 0 ? (
            <p className='loadingText'>No Matched Characters</p>
          ) : (
            <div className='character-grid' ref={charactersRef}>
              {matchedCharacters.map((characterObj) => (
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '1rem'}}>
                  <CharacterTile
                    key={characterObj.characterId}
                    character={characterObj}
                    characterVariant={1}
                    setFavoriteCharacters={setFavoriteCharacters}
                  />
                  <button className='characterSelfieButton' onClick={() => handleSelectCharacterSelfies(characterObj.characterId)}>Character Selfies</button>
                </div>
              ))}
            </div>
          )}
          {showRightArrow && (
            <img className='categoryRightArrow' onClick={() => scrollCharacters('right')} src={categoryArrow} alt='categoryArrow' />
          )}
        </div>
        <CharacterSelfiesModal
          showPopUp={showCharacterSelfies}
          setShowPopUp={setShowCharacterSelfies}
          characterId={selfieCharacterId}
        />
      </div>
    )
  }

  function BlockedCharacters() {
    const charactersRef = useRef(null)
    const [showLeftArrow, setShowLeftArrow] = useState(false)
    const [showRightArrow, setShowRightArrow] = useState(true)
    const characterHeader = 'Blocked Characters'

    useEffect(() => {
      const { current } = charactersRef
      if(current) {
        current.addEventListener('scroll', updateArrowVisibility, { passive: true })

        updateArrowVisibility()
    
        return () => {
          current.removeEventListener('scroll', updateArrowVisibility)
        }
      }
    }, [])

    const updateArrowVisibility = () => {
      const { current } = charactersRef
      if(current) {
        const maxScrollLeft = current.scrollWidth - current.clientWidth

        setShowLeftArrow(current.scrollLeft > 0)
        setShowRightArrow(current.scrollLeft < maxScrollLeft)
      }
    }

    const scrollCharacters = (direction) => {
      const { current } = charactersRef
      if(current) {
        const scrollAmount = Math.floor((current.clientWidth / 205)) * 205
        const newScrollPosition = direction === 'left' ? current.scrollLeft - scrollAmount : current.scrollLeft + scrollAmount

        current.scrollTo({
          left: newScrollPosition,
          behavior: 'smooth'
        })
      }
    }

    return (
      <div id='moga-characters' className='categoryCharacters'>
        <h3 style={{ color: '#ff2e2e', fontSize: '28px' }}>{characterHeader.charAt(0).toUpperCase() + characterHeader.slice(1)}</h3>
        <div className='categoryArrowDiv'>
          {showLeftArrow && (
            <img className='categoryLeftArrow' onClick={() => scrollCharacters('left')} src={categoryArrow} alt='categoryArrow' />
          )}
          {blockedCharacters.length === 0 ? (
            <p className='loadingText'>No Blocked Characters</p>
          ) : (
            <div className='character-grid' ref={charactersRef}>
              {blockedCharacters.map((characterObj) => (
                <CharacterTile
                  key={characterObj.characterId}
                  character={characterObj}
                  characterVariant={1}
                  setFavoriteCharacters={setFavoriteCharacters}
                />
              ))}
            </div>
          )}
          {showRightArrow && (
            <img className='categoryRightArrow' onClick={() => scrollCharacters('right')} src={categoryArrow} alt='categoryArrow' />
          )}
        </div>
      </div>
    )
  }

  function ChatubeCharacters() {
    const indexOfLastCharacter = currentPage * charactersPerPage
    const indexOfFirstCharacters = indexOfLastCharacter - charactersPerPage
    const currentCharacters = sortedCharacters.slice(indexOfFirstCharacters, indexOfLastCharacter)
    const totalPages = Math.ceil(sortedCharacters.length / charactersPerPage)

    return (
      <div className='characters'>
        {sortedCharacters.length === 0 ? (
          <p className='loadingText'>Loading...</p>
        ) : (
          <div className='all-character-grid-container'>
            {unlockPoints > 0 && (
              <p className='unlockPoints'>You have {unlockPoints} unlock points!</p>
            )}
            <div className='all-character-grid'>
              {currentCharacters.map((characterObj) => (
                <CharacterTile
                  key={characterObj.characterId}
                  character={characterObj}
                  characterVariant={1}
                  setSortedCharacters={setSortedCharacters}
                  setCategories={setCategories}
                  selectedButton={selectedButton}
                  setUnlockPoints={setUnlockPoints}
                />
              ))}
            </div>
            <div className='pagination'>
              <img className={`leftArrow ${currentPage === 1 ? 'disabled-arrow' : ''}`} onClick={() => setCurrentPage((prevPage) => Math.max(prevPage-1, 1))} src={arrowIcon} alt='arrowIcon'/>
              <span style={{color: '#292a34'}}>{`Page ${currentPage} of ${totalPages}`}</span>
              <img className={`rightArrow ${currentPage === totalPages ? 'disabled-arrow' : ''}`} onClick={() => setCurrentPage((prevPage) => Math.min(prevPage+1, totalPages))} src={arrowIcon} alt='arrowIcon'/>
            </div>
          </div>
        )}
      </div>
    )
  }

  return(
    <div className='App'>
      <header className='App-header'>
        <LeftSideBar
          setRefreshHome={setRefreshHome}
          showFavorites={showFavorites}
          setShowFavorites={setShowFavorites}
          showMatched={showMatched}
          setShowMatched={setShowMatched}
          showBlocked={showBlocked}
          setShowBlocked={setShowBlocked}
        />
        {!loading && (
          <>
            <ChatubeHeader />
            {showFavorites && (
              <FavoriteCharacters />
            )}
            {showMatched && (
              <MatchedCharacters />
            )}
            {showBlocked && (
              <BlockedCharacters />
            )}
            {selectedButton !== -2 && (
              <>
                <CategoryButtons />
                {selectedButton !== -1 && (
                  <SelectedCategory />
                )}
              </>
            )}
            <ChatubeCharacters />
            <ToastContainer />
            <Footer />
          </>
        )}

      </header>
    </div>
  )
}

export default Home
