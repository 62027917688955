export const CharacterEmotions = {
  NEUTRAL: 'Neutral',
  DISGUST: 'Disgusted',
  CONTEMPT: 'Contemptuous',
  BELLIGERENCE: 'Belligerence',
  DOMINEERING: 'Dominate',
  CRITICISM: 'Critical',
  ANGER: 'Angry',
  TENSION: 'Tense',
  TENSE_HUMOR: 'Wry',
  DEFENSIVENESS: 'Defensive',
  WHINING: 'Whiny',
  SADNESS: 'Sad',
  STONEWALLING: 'Stubborn',
  INTEREST: 'Intrigued',
  VALIDATION: 'Validated',
  AFFECTION: 'Affectionate',
  HUMOR: 'Amused',
  SURPRISE: 'Surprised',
  JOY: 'Happy'
}

export const CharacterVoices = {
  MASCULINE_VOICES: ['Alex', 'Austin', 'Brandon', 'Brett', 'Caleb', 'Christopher', 'Cody', 'Craig', 'Dennis', 'Douglas', 'Dylan', 'Edward', 'Ethan', 'Gregory', 'Hades', 
                     'Justin', 'Kyle', 'Logan', 'Malik', 'Michael', 'Philip', 'Ronald', 'Ryan', 'Shaun', 'Timothy', 'Travis', 'Tyler', 'William'],

  FEMININE_VOICES: ['Agnes', 'Amanda', 'Ashley', 'Bobbie', 'Brittany', 'Dawn', 'Deborah', 'Donna', 'Elizabeth', 'Emily', 'Emma', 'Heather', 'Jennifer', 'Jessica', 'Julia',
                    'Julie', 'Kimberly', 'Kristen', 'Lori', 'Mary', 'Megan', 'Nicole', 'Olivia', 'Rachel', 'Samantha', 'Sarah', 'Wendy'],

  ANDROGYNOUS: ['Casey', 'Jordan']
}

export const mapEmotionToEmoji = (emotion) => {
  switch(emotion.code) {
    case 'NEUTRAL':
      return '😐'
    case 'DISGUST':
      return '🤮'
    case 'CONTEMPT':
      return '😤'
    case 'BELLIGERENCE':
      return '🤬'
    case 'DOMINEERING':
      return '😠'
    case 'CRITICISM':
      return '👎'
    case 'ANGER':
      return '😡'
    case 'TENSION':
      return '😰'
    case 'TENSE_HUMOR':
      return '😬'
    case 'DEFENSIVENESS':
      return '✋'
    case 'WHINING':
      return '😩'
    case 'SADNESS':
      return '😞'
    case 'STONEWALLING':
      return '🤐'
    case 'INTEREST':
      return '🤔'
    case 'VALIDATION':
      return '👍'
    case 'AFFECTION':
      return '🥰'
    case 'HUMOR':
      return '🤣'
    case 'SURPRISE':
      return '😲'
    case 'JOY':
      return '😁'
    default:
      return ''
  }
}