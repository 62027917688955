import { useRef, useState } from 'react'
import '../../css/LessonPlans/CreateLessonPlan.css'
import { apiURL } from '../../utilities/URLs'
import { useNavigate } from 'react-router-dom'

import backButton from '../../assets/icons/backButton.png'
import { elevenLabsVoices } from '../../utilities/Languages'

export default function CreateLessonPlan() {
  const navigate = useNavigate()

  const [lessonCharacterName, setLessonCharacterName] = useState('')
  const [lessonCharacterDesc, setLessonCharacterDesc] = useState('')
  const [lessonCharactePrompt, setLessonCharacterPrompt] = useState('')
  const [lessonCharacterVoiceId, setLessonCharacterVoiceId] = useState('')
  const [lessonCharacterVoiceName, setLessonCharacterVoiceName] = useState('')
  const [lessonCharacterImage, setLessonCharacterImage] = useState(null)
  const [lessonCharacterImagePreview, setLessonCharacterImagePreview] = useState(null)

  const [lessonPlanName, setLessonPlanName] = useState('')
  const [lessonPlanDesc, setLessonPlanDesc] = useState('')
  const [lessonPlanPDF, setLessonPlanPDF] = useState(null)
  const [lessonPlanYoutubeLink, setLessonPlanYoutubeLinke] = useState('')

  const [gettingVoice, setGettingVoice] = useState(false)

  const [step, setStep] = useState(0)

  const [error, setError] = useState('')

  const audioRef = useRef(null)

  const handleCreateLessonPlan = async () => {
    if(lessonPlanName.length > 0 && lessonPlanDesc.length > 0 && (lessonPlanPDF !== null || lessonPlanYoutubeLink.length > 0)) {
      setError('Creating Lesson... Please Do Not Close Or Refresh This Window.')

      const lessonPlanInfo = {
        lessonName: lessonPlanName,
        lessonDesc: lessonPlanDesc,
        lessonPlanYoutubeLink: lessonPlanYoutubeLink
      }

      const lessonCharacter = {
        characterName: lessonCharacterName,
        characterDesc: lessonCharacterDesc,
        characterPrompt: lessonCharactePrompt,
        characterVoiceId: lessonCharacterVoiceId
      }

      const form = new FormData()
      form.append('lessonPlanInfo', JSON.stringify(lessonPlanInfo))
      form.append('lessonCharacter', JSON.stringify(lessonCharacter))
      form.append('lessonPlanPDF', lessonPlanPDF)
      form.append('lessonCharacterImage', lessonCharacterImage)

      const createLessonPlanResponse = await fetch(`${apiURL}/createLessonPlan`, {
        method: 'POST',
        body: form
      })
      const createLessonPlanData = await createLessonPlanResponse.json()
      if(createLessonPlanData.response.error) {
        setError(createLessonPlanData.response.error)
        setTimeout(() => {
          setError('')
        }, 4000)
      } else {
        setError('Lesson Plan Created! Redirecting Back To Lessons...')
        setTimeout(() => {
          navigate('/LessonPlansHome')
        }, 4000)
      }
    } else {
      setError('Please Provide All Fields.')
      setTimeout(() => {
        setError('')
      }, 4000)
    }
  }

  const handleListenToVoice = async () => {
    setGettingVoice(true)
    const voiceResponse = await fetch(`${apiURL}/listenToVoice?voiceId=${lessonCharacterVoiceId}&voiceName=${lessonCharacterVoiceName}`)
    const voiceData = await voiceResponse.json()

    setGettingVoice(false)
    if(voiceData.response.error) {
      setError(voiceData.response.error)
      setTimeout(() => {
        setError('')
      }, 4000)
    } else {
      audioRef.current.src = 'data:audio/wav;base64,'+voiceData.response.audioChunk
      audioRef.current.play()
    }
  }

  return (
    <div className='createLessonPlan'>
      <h1 className='createLessonPlanHeader'>Create Lesson Plan</h1>
      <div className='createLessonPlanContainer'>
        {step === 0 ? (
          <>
            <img onClick={() => navigate('/LessonPlansHome')} className='backButton' src={backButton} alt='backButton' />
            <div className='createLessonPlanInput'>
              <p>Lesson Character Name:</p>
              <input
                type='text'
                id='lessonPlanName'
                value={lessonCharacterName}
                maxLength={20}
                onChange={(e) => setLessonCharacterName(e.target.value)}
                placeholder='Lesson Character Name'
              />
            </div>
            <div className='createLessonPlanTextAreas'>
              <div className='createLessonPlanTextArea'>
                <p>Lesson Character Desc:</p>
                <textarea
                  type='text'
                  id='lessonPlanName'
                  value={lessonCharacterDesc}
                  maxLength={300}
                  onChange={(e) => setLessonCharacterDesc(e.target.value)}
                  placeholder='Lesson Character Desc'
                />
              </div>
              <div className='createLessonPlanTextArea'>
                <p>Lesson Character Prompt:</p>
                <textarea
                  type='text'
                  id='lessonCharacterPrompt'
                  value={lessonCharactePrompt}
                  maxLength={300}
                  onChange={(e) => setLessonCharacterPrompt(e.target.value)}
                  placeholder='Lesson Character Prompt'
                />
              </div>
            </div>
            <div className='createLessonPlanBottom'>
              <div className='createLessonPlanVoice'>
                <p>Lesson Character Voice:</p>
                <select
                  id="languageInSelect"
                  className="languageSelect"
                  value={lessonCharacterVoiceId}
                  onChange={(e) => {
                    const selectedVoiceId = e.target.value
                    setLessonCharacterVoiceId(selectedVoiceId)

                    const selectedVoice = elevenLabsVoices.find((voice) => voice.voiceId === selectedVoiceId)
                    if (selectedVoice)
                      setLessonCharacterVoiceName(selectedVoice.voiceName)
                  }}
                >
                  <option value="" disabled selected>Select input language</option>
                  {elevenLabsVoices.map((voice) => {
                    return(
                      <option id='optionBar' key={voice.voiceId} value={voice.voiceId}>{voice.voiceName}</option>
                    )
                  })}
                </select>
                <button onClick={() => handleListenToVoice()}>Listen To Voice</button>
                {gettingVoice && (
                  <p>Getting Voice...</p>
                )}
              </div>
              <div className='createLessonPlanImage'>
                <div className='createLessonPlanPDF'>
                  <p>Lesson Character Image:</p>
                  <input
                    type='file'
                    accept='image/png, image/jpeg'
                    onChange={(e) => { setLessonCharacterImage((e.target.files[0])); setLessonCharacterImagePreview(URL.createObjectURL(e.target.files[0])) }}
                  />
                </div>
                {lessonCharacterImagePreview && (
                  <img className='lessonCharacterPreview' src={lessonCharacterImagePreview} alt='characterPreview' />
                )}
              </div>
            </div>
            <button onClick={() => { 
              if(lessonCharacterName.length > 0 && lessonCharacterDesc.length > 0 && lessonCharacterVoiceId.length > 0 && lessonCharacterImage !== null)
               setStep(step+1) 
              else {
                setError('Please Provide All Fields.')
                setTimeout(() => {
                  setError('')
                }, 4000)
              }
            }} 
            className='createLessonPlanButton2'>Next</button>
            {error.length > 0 && (
              <p className='createLessonPlanError'>{error}</p>
            )}
          </>
        ) : (
          <>
            <img onClick={() => setStep(step-1)} className='backButton' src={backButton} alt='backButton' />
            <div className='createLessonPlanInput'>
              <p>Lesson Plan Name:</p>
              <input
                type='text'
                id='lessonPlanName'
                value={lessonPlanName}
                maxLength={100}
                onChange={(e) => setLessonPlanName(e.target.value)}
                placeholder='Lesson Plan Name'
              />
            </div>
            <div className='createLessonPlanTextArea2'>
              <p>Lesson Plan Desc:</p>
              <textarea
                type='text'
                id='lessonPlanName'
                value={lessonPlanDesc}
                maxLength={300}
                onChange={(e) => setLessonPlanDesc(e.target.value)}
                placeholder='Lesson Plan Desc'
              />
            </div>
            <div className='createLessonPlanPDF'>
              <p>Upload Lesson Plan PDF:</p>
              <input
                type='file'
                accept='application/pdf'
                onChange={(e) => setLessonPlanPDF((e.target.files[0]))}
              />
            </div>
            <div className='orSeparator'>
              <p>OR</p>
            </div>
            <div className='createLessonPlanYouTube'>
              <p>Enter YouTube Link:</p>
              <input
                type='text'
                placeholder='https://www.youtube.com/watch?v=...'
                value={lessonPlanYoutubeLink}
                onChange={(e) => setLessonPlanYoutubeLinke(e.target.value)}
              />
            </div>
            <p className='noteText'>NOTE: uploading youtube videos can take up to 5 minutes to display on the home page.</p>
            <button onClick={() => handleCreateLessonPlan()} className='createLessonPlanButton3'>Create Lesson Plan</button>
            {error.length > 0 && (
              <p className='createLessonPlanError'>{error}</p>
            )}
          </>
        )}
        <audio ref={audioRef} />
      </div>
    </div>
  )
}