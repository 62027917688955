import React, { useEffect, useRef, useState } from 'react'
import { apiURL } from '../../utilities/URLs'
import { Avatar } from '@readyplayerme/visage'
import { LoginCreds } from '../../utilities/loginCreds'
import { LanguageToChinese, Languages } from '../../utilities/Languages'

import '../../css/FarmersMarketDemo.css'

import farmersMarketDemo from '../../assets/icons/farmersMarketBackground.jpg'
import blankProfilePicture from '../../assets/icons/BlankProfilePicture.png'

import idleAnimation from '../../assets/animations/idleAnimation.fbx'
import wavingAnimation from '../../assets/animations/wavingAnimation.fbx'
import talkingAnimation from '../../assets/animations/talkingAnimation.fbx'

const PoppyTheTutor = {
  userPersonaDevToken: '804a68b8-c902-40f1-9c9a-02dfed1d5aa1',
  userPersonaId: 'f864c30e-926c-42e7-ac6d-ea0f3a4574a2',
  userPersonaVoiceId: 'ThT5KcBeYPX3keUQqHPh'
}

function FarmersMarketDemo() {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')

  const [poppyCharacter, setPoppyCharacter] = useState(null)
  const [sessionToken, setSessionToken] = useState('')
  
  const [userMessage, setUserMessage] = useState('')
  const [messages, setMessages] = useState([])
  const audioRef = useRef(null)

  const [languageIn, setLanguageIn] = useState('')
  const [languageOut, setLanguageOut] = useState('')

  const [currentAnimation, setCurrentAnimation] = useState(wavingAnimation)

  useEffect(() => {
    setLoading(true)
    fetch(`${apiURL}/getUserPersona?devToken=${PoppyTheTutor.userPersonaDevToken}&userPersonaId=${PoppyTheTutor.userPersonaId}`)
    .then((res) => res.json())
    .then((data) => {
      if(data.response.error)
        console.error(data.response.error)
      else {
        setPoppyCharacter(data.response.userPersona)

        fetch(`${apiURL}/getSessionToken?userName=${LoginCreds.userName !== '' ? LoginCreds.userName : 'Guest'}&isUserPersona=1&characterId=${PoppyTheTutor.userPersonaId}&audio=1&emotion=0`)
        .then((res) => res.json())
        .then((data) => {
          if(data.response.error){
            setError(data.response.error)
          } else
            setSessionToken(data.response.sessionToken)
        })
      }
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    document.documentElement.scrollTop = document.documentElement.scrollHeight
    var messageBody = document.getElementById('chat_box')
    if(messageBody)
      messageBody.scrollTop = messageBody.scrollHeight
  }, [messages])

  useEffect(() => {
    setTimeout(() => {
      console.log('CHANGE')
      setCurrentAnimation(idleAnimation)
    }, 4000)
  }, [])

  window.addEventListener('beforeunload', function (e) {
    closeSession()
  })

  const handleKeyEvent = (key) => {
    if(key === 'Enter') {
      handleSendMessage()
    }
  }

  const handleSendMessage = () => {
    if(userMessage !== '' && languageIn !== '' && languageOut !== '') {
      setMessages((prevMessages) => [...prevMessages, {characterId: 0, message: userMessage}])
      setUserMessage('')
      setError('Loading...')
      
      fetch(`${apiURL}/sendMessageTranslate?message=${userMessage}&srcLang=${languageIn}&tgtLang=${languageOut}&voiceId=${PoppyTheTutor.userPersonaVoiceId}&token=${sessionToken}`)
      .then((res) => res.json())
      .then((data) => {
        setCurrentAnimation(talkingAnimation)
        handleAddMessage(data.response.messages, 0)
        setError('')
      })
    } else {
      setError('Please Provide A Message And Have Languages Selected.')
      setTimeout(() => {
        setError('')
      }, 2000)
    }
  }

  const handleAddMessage = (newMessages, index) => {
    if(audioRef.current !== null) {
      if(index < newMessages.length) {
        setMessages((prevMessages) => [...prevMessages, newMessages[index]])

        audioRef.current.src = 'data:audio/wav;base64,'+newMessages[index].audioChunk
        audioRef.current.play()
        audioRef.current.onended = () => {
          handleAddMessage(newMessages, index+1)
        }
      } else
        setCurrentAnimation(idleAnimation)
    }
  }

  const closeSession = () => {
    fetch(`${apiURL}/closeSession?token=${sessionToken}`)
    .then((res) => res.json())
  }

  return (
    <div className='farmersMarketContainer'>
      <img className='farmersMarketBackground' src={farmersMarketDemo} alt='farmersMarketBackground' />
      {loading ? (
        <p>Fetching Poppy...</p>
      ) : (
        <>
          <div className='farmersMarketLeftSide'>
            <Avatar className='farmersMarketAvatar' modelSrc={poppyCharacter.userPersonaRPMAvatarURL} animationSrc={currentAnimation} cameraInitialDistance={1} />
          </div>
          <div className='farmersMarketRightSide'>
            <div id='chat_box' className='farmersMarketChatBox'>
              {messages.length > 0 &&
                messages.map((message, index) => (
                  message.characterId !== 0 ? (
                    <div key={index} className='characterMessage'>
                      <div className='messageImage'>
                        <img src={poppyCharacter.userPersonaImage} alt='avatarImg' />
                        <p>{poppyCharacter.userPersonaName}</p>
                      </div>
                      <p>{message.message}</p>
                    </div>
                  ) : (
                    <div key={index} className='userMessage'>
                      <p>{message.message}</p>
                      <div className='messageImage'>
                        <img src={LoginCreds.profileImage !== '' ? LoginCreds.profileImage : blankProfilePicture} alt='avatarImg' />
                        <p>{LoginCreds.userName !== '' ? LoginCreds.userName : 'Guest'}</p>
                      </div>
                    </div>
                  )
                ))
              }
              {error.length > 0 && (
                <p className='farmersMarketError'>{error}</p>
              )}
            </div>
            <audio ref={audioRef} />
            <div className='farmersMarketSelectLanguages'>
              <select value={languageIn} onChange={(e) => setLanguageIn(e.target.value)}>
                <option value='' disabled selected>Select input language</option>
                {Object.keys(Languages).map((language) => {
                  return(
                    <option id='optionBar' key={language} value={language}>{language + ' / ' + LanguageToChinese[language]}</option>
                  )
                })}
              </select>
              <select value={languageOut} onChange={(e) => setLanguageOut(e.target.value)}>
                <option value='' disabled selected>Select output language</option>
                {Object.keys(Languages).map((language) => {
                  return(
                    <option key={language} value={language}>{language + ' / ' + LanguageToChinese[language]}</option>
                  )
                })}
              </select>            
              </div>
            <div className='farmersMarketInputBox'>
              <input
                type='text'
                value={userMessage}
                onKeyDown={(e) => handleKeyEvent(e.key)}
                onChange={(e) => setUserMessage(e.target.value)}
                placeholder='Enter message...'
              />
              <button disabled={userMessage === '' || languageIn === '' || languageOut === ''} className={userMessage === '' || languageIn === '' || languageOut === '' ? 'farmersMarketDisabledButton' : 'farmersMarketEnabledButton'} onClick={handleSendMessage}>Send</button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default FarmersMarketDemo