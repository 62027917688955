import { loadStripe } from "@stripe/stripe-js";

export const SubscriptionTiers = [
  {
    subTier: 0,
    subAmount: "0.00",
  },
  {
    subTier: 1,
    subAmount: "4.99",
  },
  {
    subTier: 2,
    subAmount: "9.99",
  },
];

export const UserInteractions = [
  {
    userInteractions: 500,
    amount: 499,
  },
  {
    userInteractions: 1000,
    amount: 799,
  },
  {
    userInteractions: 2000,
    amount: 999,
  },
];

export const MatchSlots = [
  {
    matchSlots: 5,
    amount: 499,
  },
];

export let currentUserInteraction = null;

export function setCurrentUserInteraction(userInteraction) {
  currentUserInteraction = userInteraction;
}

export const stripePromise = loadStripe(
  "pk_live_xdpXQzjhRAogkjMeacGPHXL0006qjnyhru"
);
