import { useNavigate } from 'react-router-dom'
import '../css/NotFound.css'

function NotFound() {
  const navigate = useNavigate()

  return (
    <div className='notFoundContainer'>
      <h1>404 Not Found</h1>
      <p>The page you are looking for does not exist, try any of these links here!</p>
      <button onClick={() => navigate('/')}>Home</button>
      <button onClick={() => navigate('/login')}>Login</button>
      <button onClick={() => navigate('signUp')}>Sign Up</button>
    </div>
  )
}

export default NotFound